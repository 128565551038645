import React from 'react';
import Box from '../components/Box';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Organitzacio = ({ lang }: Props) => {
  let man = 'Sr';
  let woman = 'Sra';
  let manDr = 'Dr';
  let womanDr = 'Dra';
  let title1 = 'Comitè Organitzador';
  let box1Title = 'Presidència';
  let box1Content1 = 'Secretària general de l’Esport i de l’Activitat Física';
  let box1Content2 = 'Director de l’INEFC';
  let box2Title = 'Secretaria Tècnica';
  let box3Title = 'Tresoreria';
  let box4Title = 'Comunicació';
  let title2 = 'Comitè Científic';
  let box5Title = 'Presidència';
  let box6Title = 'Membres';
  let title3 = 'Comitè d’Honor';
  let box7Content1 = 'Consellera de la Presidència';
  let box7Content2 = 'Consellera d’Igualtat i Feminismes';
  if(lang === Lang.en) {
    man = 'Mr';
    woman = 'Ms';
    manDr = 'Dr';
    womanDr = 'Dr';
    title1 = 'Organizing Committee';
    box1Title = 'Presidency';
    box1Content1 = 'Secretary General for Sport and Physical Activity';
    box1Content2 = 'Director of INEFC';
    box2Title = 'Technical secretary';
    box3Title = 'Treasury';
    box4Title = 'Communication';
    title2 = 'Scientific Committee';
    box5Title = 'Presidency';
    box6Title = 'Members';
    title3 = 'Honor Committee';
    box7Content1 = 'Minister of the Presidency of the Government of Catalonia';
    box7Content2 = 'Minister for Equality and Feminisms of the Government of Catalonia';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.organitzacio} />
      <div className="organitzacio">
        <h2 className="optionTitle">{title1}</h2>
        <div className="comiteOrganitzador">
          <Box title={box1Title} className="presidenciaBox">
            <>
              <div className="boxCol">
                <div>{woman}. Anna Caula<br />{box1Content1}</div>
              </div>
              <div className="boxCol">
                <div>{manDr}. Eduard Inglés<br />{box1Content2}</div>
              </div>
            </>
          </Box>
          <div className="grid">
            <div className="gridCol">
              <Box title={box2Title}>
                <>
                  <div className="boxCol">
                    <div>{woman}. Elena Gil<br />INEFC</div>
                  </div>
                  <div className="boxCol">
                    <div>{woman}. Laia Muñoz<br />SGEiAF</div>
                  </div>
                </>
              </Box>
            </div>
            <div className="gridCol">
              <Box title={box3Title}>
                <div className="boxCol" style={{ width: '100%' }}>
                  <div>{man}. Josep Vilà<br/>INEFC</div>
                </div>
              </Box>
            </div>
            <div className="gridCol">
              <Box title={box4Title}>
                <>
                  <div className="boxCol">
                    <div>{woman}. Judit Rodà<br />INEFC</div>
                  </div>
                  <div className="boxCol">
                    <div>{man}. Albert Mayol<br />SGEiAF</div>
                  </div>
                </>
              </Box>
            </div>
          </div>
        </div>
        <h2 className="optionTitle">{title2}</h2>
        <div className="comiteCientific">
          <Box title={box5Title} className="presidenciaBox">
            <>
              <div className="boxCol">
                <div>{womanDr}. Susanna Soler<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Anna Vilanova<br />INEFC Barcelona</div>
              </div>
            </>
          </Box>
          <Box title={box6Title} className="membres">
            <>
              <div className="boxCol">
                <div>{womanDr}. Silvia Aranda García<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{woman}. Elisabeth Boloix i Ripoll<br />COPLEFC</div>
              </div>
              <div className="boxCol">
                <div>{man}. Pedro Luis Cosio Fernández<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Maribel Cuadrado Santañes<br />INEFC Lleida</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Estela Farías Torbidoni<br />INEFC Lleida</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Raquel Font Lladó<br />EUSES UdG</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Ingrid Hinojosa Alcalde<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{manDr}. Xavier Iglesias i Reig<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Cati Lecumberri Gómez<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Teresa Lleixà  Arribas<br />Universitat de Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{manDr}. José Luis López del Amo<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Montse Martín Horcajo<br />UVIC-UCC</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Raquel Mirabet Agulled<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Marta Moragas i Rovira<br />URL - Blanquerna</div>
              </div>
              <div className="boxCol">
                <div>{woman}. Lia Moreno Simonet<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{man}. Joshua Muñoz Vázquez<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{woman}. Blanca Nualart Barba<br />Consell Català de l’Esport</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Alba Pardo Fernández<br />TecnoCampus Mataró</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Sílvia Puigarnau Coma<br />INEFC Pirineus</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Mertixell Puyané Oliva<br />TecnoCampus Mataró</div>
              </div>
              <div className="boxCol">
                <div>{man}. Raimon Recoder Miralles<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Dolors Ribalta Alcalde<br />URL - Blanquerna</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Rosa Rodríguez Arregui<br />INEFC Lleida</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Mireia Salvador Lluesma<br />EUSES Terres Ebre</div>
              </div>
              <div className="boxCol">
                <div>{woman}. Joana Sans Osanz<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{manDr}. Jordi Seguí Urbaneja<br />INEFC Lleida</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Pedrona Serra Payeras<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{manDr}. Rafael Tarragó García<br />INEFC Barcelona</div>
              </div>
              <div className="boxCol">
                <div>{womanDr}. Carlota Torrents Martín<br />INEFC Lleida</div>
              </div>
              <div className="boxCol">
                <div>{woman}. Sílvia Tuyà Viñas<br />INEFC Barcelona</div>
              </div>
            </>
          </Box>
        </div>
        <h2 className="optionTitle">{title3}</h2>
        <div className="comiteHonor">
          <div className="simpleBox">
            <div className="content">
              <div className="boxCol">
                <div>{woman}. Laura Vilagrà i Pons<br />{box7Content1}</div>
              </div>
              <div className="boxCol">
                <div>{woman}. Tània Verge i Mestre<br />{box7Content2}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Organitzacio;