import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Benvinguda = ({ lang }: Props) => {
  let title1 = 'Salutació institucional';
  let title2 = 'Benvingudes al Congrés La Igualtat en Joc';
  let p1 = 'La visibilitat i el reconeixement de l’esport femení és una peça fonamental de la transformació feminista que estem portant a terme a Catalunya i que es viu als carrers, al govern, a les escoles, als centres esportius.';
  let p2 = 'Treballem per construir un país més just per a tothom, dones i homes, lliure de discriminacions i amb igualtat d’oportunitats. L’esport és un àmbit on, a més de practicar una activitat física, passen moltes més coses i l’ADN esportiu català comparteix valors i gens amb el nostre ADN de país.';
  let p3 = 'L\'esport femení és de màxim nivell i qualitat. Les expressions com ara “corres o xutes la pilota com una nena” no han de ser mai més un insult, perquè són motiu d’orgull. L’esport femení del nostre país està fent història i les esportistes sou referents poderosos per a les dones, especialment per a les més joves i per a les nenes.';
  let p4 = 'De la mateixa manera que als esports s’ha d’anivellar la pista per a un joc just, a les polítiques de l’esport (i a tots els àmbits) hem d’anivellar la pista per a les dones i també altres grups que pateixen discriminació o marginalització. Perquè les polítiques feministes han de ser també interseccionals, i hem d’aconseguir que arribin a les persones racialitzades, les persones migrades i refugiades, les persones amb accés limitat als recursos econòmics, entre d’altres.';
  let p5 = 'Quan diem que aquesta és la legislatura de l’esport femení, ho fem perquè ens ho creiem. Hem passat de les paraules als fets. Hem multiplicat per quatre els ajuts a l’esport femení i li estem donant més  visibilitat. No ho fem soles: hem involucrat tots els agents implicats, entitats, federacions, món local, sector educatiu i mitjans de comunicació.';
  let p6 = 'Volem ser al costat de les nenes i joves perquè puguin practicar l’esport que vulguin, en igualtat de condicions. Estem donant suport a les esportistes, potenciant la competició d’elit, impulsant l’esport base i col·laborant amb clubs i entitats que aposten per l’esport femení.';
  let p7 = 'I ho fem convençudes que és la millor manera d’avançar en la lluita contra els estereotips, el masclisme, la falta de patrocinadors, la bretxa salarial i per vèncer, també, els obstacles i les reticències perquè les dones puguin accedir als quadres tècnics i directius dels clubs esportius.';
  let p8 = 'Des del Govern, us animem a continuar per aquest camí, per anar assegurant els avenços i a la vegada eixamplar-los per fer-los arribar a totes les dones, diverses i de tots els grups socials del nostre país.  Perquè l\'esport té el potencial per canviar vides i per canviar la nostra societat. ';
  let boxSub1 = 'Consellera de la Presidència';
  let boxSub2 = 'Consellera d\'Igualtat i Feminismes';
  if(lang === Lang.en) {
    title1 = 'Institutional greeting';
    title2 = 'Welcome to the Congress Equality on the Move';
    p1 = 'The visibility and recognition of women’s sport is a fundamental part of the feminist transformation we are carrying out in Catalonia, which is experienced in the streets, in the government, in schools, and in sports centers.';
    p2 = 'We work to build a fairer nation for all, women and men, free of discrimination and with equal opportunities. Sport is an area where, in addition to practicing a physical activity, many more things happen, and the Catalan sporting DNA shares values and genes with our nation’s DNA.';
    p3 = 'Women’s sport is of the highest level and quality. Expressions such as “you run or you kick the ball like a girl” should never be an insult again, because they are a source of pride. Women’s sport in our country is making history and female athletes are powerful references for women, especially for young women and girls.';
    p4 = 'Just as in sports we need to level the playing field for fair play, in sports politics (and in all areas) we need to level the playing field for women as well as other groups who suffer discrimination or marginalization. Because feminist policies must also be intersectional, and we must also manage to level the playing field so that they reach racialized people, migrants and refugees, people with limited access to economic resources, among others.';
    p5 = 'When we say that this is the legislature of women’s sport, we do so because we believe it. We have gone from words to deeds. We have quadrupled aid to women’s sport and we are making it more visible. We are not doing it alone: we have worked together with all the involved agents, entities, federations, the local world, the education sector and also the media.';
    p6 = 'We want to stand with girls and young women so that they can practice the sport they want, under equal conditions. We are supporting female athletes, promoting elite competition and grassroots sport, and collaborating with clubs and organizations that commit themselves to women’s sport.';
    p7 = 'And we do so with the conviction that this is the best way to move forward in the fight against stereotypes, male chauvinism, the lack of sponsors, the wage gap, and also to overcome the obstacles and reluctance so that women can access the management and technical cadres of sports clubs.';
    p8 = 'From the Government, we encourage you to continue on this path, to ensure progress and at the same time broaden it to reach all women, diverse and from all social groups in our country. Because sport has the potential to change lives and to change our society.';
    boxSub1 = 'Minister of the Presidency of the Government of Catalonia';
    boxSub2 = 'Minister for Equality and Feminisms of the Government of Catalonia';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.benvinguda} />
      <div className="benvinguda">
        <div className="title">{title1}</div>
        <h1 className="title bold">{title2}</h1>
        <div className="text">
          {p1}
          <br /><br />
          {p2}
          <br /><br />
          {p3}
          <br /><br />
          {p4}
          <br /><br />
          {p5}
          <br /><br />
          {p6}
          <br /><br />
          {p7}
          <br /><br />
          {p8}
          <br /><br />
        </div>
      </div>
      <div className="boxes" style={{ marginBottom: 40 }}>
        <div className="box boxLeft">
          <div className="img benvingudaLeft"></div>
          <div className="boxesLabel">
            Laura Vilagrà i Pons
            <div className="sub">{boxSub1}</div>
          </div>
        </div>
        <div className="box boxRight">
          <div className="img benvingudaRight"></div>
          <div className="boxesLabel">
            Tània Verge i Mestre
            <div className="sub">{boxSub2}</div>
          </div>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Benvinguda;