import { FirebaseApp } from 'firebase/app';
import { IServiceFactory } from '../IServiceFactory';
import { IRegistrationService } from '../IRegistrationService';
import { IEventService } from '../IEventService';
import { RegistrationService } from './RegistrationService';
import { EventService } from './EventService';
import { IAuthService } from '../IAuthService';
import { AuthService } from './AuthService';

export class ServiceFactory implements IServiceFactory {
  private registrationService: IRegistrationService;
  private eventService: IEventService;
  private authService: IAuthService;

  constructor(app: FirebaseApp) {
    this.registrationService = new RegistrationService(app, 'registrations');
    this.eventService = new EventService();
    this.authService = new AuthService(app);
  }

  getRegistrationService(): IRegistrationService {
    return this.registrationService;
  }

  getEventService(): IEventService {
    return this.eventService;
  }

  getAuthService(): IAuthService {
    return this.authService;
  }
}