import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Spot = ({ lang }: Props) => {
  let title = 'Espot del Congrés';
  if(lang === Lang.en) {
    title = 'Spot of the Congress';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.spot} />
      <div className="spot">
        <h1 className="optionTitle">{title}</h1>
        <div className="grid">
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgHorizontal"></div>
              <a href="/content/Spot_LIEJ.mp4" download={'Spot_LIEJ_horitzontal.mp4'}>
                <div className="gridBoxLabel">1920 x 1080</div>
              </a>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgVertical"></div>
              <a href="/content/Spot_LIEJ_vertical.mp4" download={'Spot_LIEJ_vertical.mp4'}>
                <div className="gridBoxLabel">1080 x 1920</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Spot;