import React from 'react';
import { Option } from '../model/MenuOptions';
import LanguageMenu from './LanguageMenu';
import Menu from './Menu';

const OptionHeader = ({ lang, option }: Props) => {
  return (
    <div className="optionHeader">
      <LanguageMenu lang={lang} />
      <Menu lang={lang} open={false} option={option} />
    </div>
  );
}

interface Props {
  lang: string,
  option: Option
}

export default OptionHeader;