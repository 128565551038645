import React from 'react';
import { Lang } from '../model/Lang';

const Activity = ({ lang }: Props) => {
  let title = 'Me la passes?';
  let location = 'Terrassa';
  let description = 'INEFC Lleida en col·laboració amb Companyia Còrnia i Associació Femenina d\'Esport de Lleida';
  if(lang === Lang.en) {
    title = 'Can you pass it to me?';
    location = 'Terrace';
    description = 'INEFC Lleida in collaboration with Company Còrnia and the Feminine Sport Association of Lleida.';
  }
  return (
    <div className="event activity">
      <div className="title">{title}</div>
      <div className="textSmall">{description}</div>
      <div className="text ital">({location})</div>
    </div>
  );
}

interface Props {
  lang: string
}

export { Activity }