import React, { useEffect, useState } from 'react';
import { Lang } from '../model/Lang';
import { Workshop } from '../model/Workshop';
import YesNo from './YesNo';

const WorkshopForm = ({ lang, title, day, unavailable, invalid, onAssist, onChange }: Props) => {
  const [assist, setAssist] = useState(false);
  const [selected, setSelected] = useState('');
  const [workshopsCapacity, setWorkshopsCapacity] = useState({});

  useEffect(() => {
    const load = async () => {
      let result = await fetch('https://laigualtatenjoc.cat/capacity/' + day);
      const capacity = await result.json();
      console.log('capacity', capacity);
      setWorkshopsCapacity(capacity);
    }
    load();
  }, []);

  const onChangeAssist = (value: boolean) => {
    setAssist(value);
    onAssist(value);
    if(!value) {
      setSelected('');
      onChange('');
    }
  }

  const onChangeWorkshop = (e: any) => {
    const selected = e.target.checked ? e.target.name : '';
    setSelected(selected);
    onChange(selected);
  }

  const disabled = (workshop: string, checkUnavailable: boolean) => {
    return (checkUnavailable && unavailable === workshop) || workshopsCapacity[workshop] <= 0;
  }

  let subtitle = 'Tots els tallers es celebren simultàniament a la mateixa hora';
  let header = 'Marca el taller de la teva elecció*';
  let limit = '*Cada taller està limitat a un màxim de 40 assistents, prevaldrà l’ordre d’inscripció.';
  let workshop1 = 'Taller I: Masculinitats i esport. Miquel Far Ferrer';
  let workshop2 = 'Taller II: Esport segur: prevenció de les violències sexuals. Carla Vall i Duran';
  let workshop3 = 'Taller III: Comunicació per al canvi. Clara Basiana Cañellas';
  let workshop4 = 'Taller IV: Igualtat en l’esport des de la gestió esportiva local. Ainhoa Azurmendi Echeragay';
  let workshop5 = 'Taller V: Com fer plans d’igualtat en les organitzacions esportives. Anna Rovira Puig';
  let workshop6 = 'Taller VI: Esport i salut amb perspectiva de gènere. Eva Ferrer Vidal-Barraquer';
  if(lang === Lang.en) {
    subtitle = 'All workshops take place simultaneously and start at the same time.';
    header = 'Select the workshop in which you wish to participate*';
    limit = '*Each workshop will be limited a maximum of 40 participants; the order of registration will determine participants.';
    workshop1 = 'Workshop I: Masculinities and Sport. Miquel Far Ferrer';
    workshop2 = 'Workshop II: Safe Sport: Preventing Sexual forms of Violence. Carla Vall i Duran';
    workshop3 = 'Workshop III: Communication for Change. Clara Basiana Cañellas';
    workshop4 = 'Workshop IV: Equality in Sport at the Local Management Level. Ainhoa Azurmendi Echeragay';
    workshop5 = 'Workshop V: How to Draw Up Equality Plans at Sports Organisations. Anna Rovira Puig';
    workshop6 = 'Workshop VI: Sport and Health with a Gender Perspective. Eva Ferrer Vidal-Barraquer';
  }
  return (
    <>
      <div className={`label bold itemMt ${invalid && 'invalid'}`}>{title}</div>
      <div className="subtitle">{subtitle}</div>
      <YesNo lang={lang} onChange={onChangeAssist} />
      {assist && <div>
        <div className="label itemHalfMt">{header}</div>
        <span className="control">
          <input type="checkbox" name={Workshop.I} checked={selected === Workshop.I} onChange={onChangeWorkshop} disabled={disabled(Workshop.I, true)} className="inputCheckbox" />
          <div className="label">{workshop1} {disabled(Workshop.I, false) && <span className="invalid">(Places exhaurides)</span>}</div>
        </span>
        <span className="control">
          <input type="checkbox" name={Workshop.II} checked={selected === Workshop.II} onChange={onChangeWorkshop} disabled={disabled(Workshop.II, true)} className="inputCheckbox" />
          <div className="label">{workshop2} {disabled(Workshop.II, false) && <span className="invalid">(Places exhaurides)</span>}</div>
        </span>
        <span className="control">
          <input type="checkbox" name={Workshop.III} checked={selected === Workshop.III} onChange={onChangeWorkshop} disabled={disabled(Workshop.III, true)} className="inputCheckbox" />
          <div className="label">{workshop3} {disabled(Workshop.III, false) && <span className="invalid">(Places exhaurides)</span>}</div>
        </span>
        <span className="control">
          <input type="checkbox" name={Workshop.IV} checked={selected === Workshop.IV} onChange={onChangeWorkshop} disabled={disabled(Workshop.IV, true)} className="inputCheckbox" />
          <div className="label">{workshop4} {disabled(Workshop.IV, false) && <span className="invalid">(Places exhaurides)</span>}</div>
        </span>
        <span className="control">
          <input type="checkbox" name={Workshop.V} checked={selected === Workshop.V} onChange={onChangeWorkshop} disabled={disabled(Workshop.V, true)} className="inputCheckbox" />
          <div className="label">{workshop5} {disabled(Workshop.V, false) && <span className="invalid">(Places exhaurides)</span>}</div>
        </span>
        <span className="control">
          <input type="checkbox" name={Workshop.VI} checked={selected === Workshop.VI}  onChange={onChangeWorkshop} disabled={disabled(Workshop.VI, true)} className="inputCheckbox" />
          <div className="label">{workshop6} {disabled(Workshop.VI, false) && <span className="invalid">(Places exhaurides)</span>}</div>
        </span>
        <div className="subtitle">{limit}</div>
      </div>}
    </>
  );
}

interface Props {
  lang: string,
  title: string,
  day: string,
  unavailable: string,
  invalid: boolean,
  onAssist: (value: boolean) => void,
  onChange: (workshop: string) => void
}

export default WorkshopForm;