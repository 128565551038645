import { FirebaseApp } from 'firebase/app';
import { collection, doc, setDoc, getDoc, QueryDocumentSnapshot, getFirestore, Firestore, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { Model } from '../../model/Model';
import { IModelService } from '../IModelService';

const modelConverter = <T extends Model>() => ({
  toFirestore: (model: T) => {
    const data = { ...model };
    delete data.id;
    return data;
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const model = snapshot.data() as T;
    model.id = snapshot.id;
    return model;
  }
});

export abstract class ModelService<T extends Model> implements IModelService<T> {
  protected database: Firestore;
  protected path: string;
  protected converter = modelConverter;

  constructor(app: FirebaseApp, path: string) {
    this.path = path;
    this.database = getFirestore(app);
  }

  async get(id: string): Promise<T | null> {
    const ref = doc(this.database, this.path, id).withConverter(this.converter<T>());
    const snapshot = await getDoc(ref);
    if(snapshot.exists()) {
      return snapshot.data();
    }
    return null;
  }

  async create(model: T): Promise<T> {
    const ref = doc(collection(this.database, this.path)).withConverter(this.converter<T>());
    await setDoc(ref, model);
    model.id = ref.id;
    return model;
  }

  async update(id: string, fields: any): Promise<void> {
    const ref = doc(this.database, this.path, id).withConverter(this.converter<T>());
    await updateDoc(ref, fields);
  }

  async delete(model: T): Promise<void> {
    if(model.id) {
      const ref = doc(this.database, this.path, model.id);
      await deleteDoc(ref);
    }
  }

  async all(): Promise<T[]> {
    const ref = collection(this.database, this.path).withConverter(this.converter<T>());
    const docs = await getDocs(ref);
    return docs.docs.map(d => d.data());
  }
} 