import React from 'react';
import { useParams } from 'react-router-dom';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Conclusions = ({ lang }: Props) => {
  let title = 'Conclusions';
  let description = 'Aquest document aplega el conjunt d’aportacions recollides en el Congrés La Igualtat en Joc en tres idiomes (català, castellà i anglès).';
  let descargat = 'DESCARREGA’T LES CONCLUSIONS';
  if(lang === Lang.en) {
    description = 'This document gathers the set of contributions collected at the Congress Equality on the Move in three languages (English, Catalan and Spanish).';
    descargat = 'DOWNLOAD THE CONCLUSIONS';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.conclusions} />
      <div className="conclusions">
        <h1 className="optionTitle">{title}</h1>
        <div className="title">{description}</div>
        <div className="subtitle">
          Este documento reúne el conjunto de aportaciones recogidas en el Congreso La Igualdad en Juego en tres idiomas (catalán, inglés y castellano).
        </div>
        <a className="link" href={'/content/Conclusions_LIEJ.pdf'} download={'Conclusions_LIEJ.pdf'}>{descargat}</a>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Conclusions;