import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OptionHeader from '../components/OptionHeader';
import YesNo from '../components/YesNo';
import WorkshopForm from '../components/WorkshopForm';
import { MenuOptions } from '../model/MenuOptions';
import { Registration } from '../model/Registration';
import { Workshop } from '../model/Workshop';
import { IServiceFactory } from '../services/IServiceFactory';
import { Lang } from '../model/Lang';

const Formulari = ({ lang, serviceFactory }: Props) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [nameInvalid, setNameInvalid] = useState(false);
  const [lastNames, setLastNames] = useState('');
  const [lastNamesInvalid, setLastNamesInvalid] = useState(false);
  const [dni, setDni] = useState('');
  const [dniInvalid, setDniInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [institution1, setInstitution1] = useState('');
  const [institution1Invalid, setInstitution1Invalid] = useState(false);
  const [institution2, setInstitution2] = useState('');
  const [institution2Invalid, setInstitution2Invalid] = useState(false);
  const [position, setPosition] = useState('');
  const [positionInvalid, setPositionInvalid] = useState(false);
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [floor, setFloor] = useState('');
  const [door, setDoor] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');
  const [country, setCountry] = useState('');
  const [locationInvalid, setLocationInvalid] = useState(false);
  const [presentOralCommunication, setPresentOralCommunication] = useState(false);
  const [assist26, setAssist26] = useState<boolean | null>(null);
  const [assist26Invalid, setAssist26Invalid] = useState(false);
  const [assist27, setAssist27] = useState<boolean | null>(null);
  const [assist27Invalid, setAssist27Invalid] = useState(false);
  const [lunch, setLunch] = useState<boolean | null>(null);
  const [lunchInvalid, setLunchInvalid] = useState(false);
  const [lunchIntolerances, setLunchIntolerances] = useState('');
  const [needReceipt, setNeedReceipt] = useState<null | boolean>(null);
  const [needReceiptInvalid, setNeedReceiptInvalid] = useState(false);
  const [receiptName, setReceiptName] = useState('');
  const [receiptNameInvalid, setReceiptNameInvalid] = useState(false);
  const [receiptLastNames, setReceiptLastNames] = useState('');
  const [receiptLastNamesInvalid, setReceiptLastNamesInvalid] = useState(false);
  const [receiptDni, setReceiptDni] = useState('');
  const [receiptDniInvalid, setReceiptDniInvalid] = useState(false);
  const [receiptAddress, setReceiptAddress] = useState('');
  const [receiptAddressInvalid, setReceiptAddressInvalid] = useState(false);
  const [assistWorkshop27, setAssistWorkshop27] = useState<boolean | null>(null);
  const [assistWorkshop28, setAssistWorkshop28] = useState<boolean | null>(null);
  const [workshop27, setWorkshop27] = useState('');
  const [workshop27Invalid, setWorkshop27Invalid] = useState(false);
  const [workshop28, setWorkshop28] = useState('');
  const [workshop28Invalid, setWorkshop28Invalid] = useState(false);
  const [consentData, setConsentData] = useState(false);
  const [consentDataInvalid, setConsentDataInvalid] = useState(false);
  const [authorizeImage, setAuthorizeImage] = useState(false);
  const [authorizeImageInvalid, setAuthorizeImageInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const invalidRef = useRef(false);
  const invalidYRef = useRef(0);

  const onChangeName = (e: any) => {
    setName(e.target.value);
  }

  const onChangeLastNames = (e: any) => {
    setLastNames(e.target.value);
  }

  const onChangeDni = (e: any) => {
    setDni(e.target.value);
  }

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  }

  const onChangeInstitution1 = (e: any) => {
    setInstitution1(e.target.value);
  }

  const onChangeInstitution2 = (e: any) => {
    setInstitution2(e.target.value);
  }

  const onChangePosition = (e: any) => {
    setPosition(e.target.value);
  }

  const onChangeStreet = (e: any) => {
    setStreet(e.target.value);
  }

  const onChangeNumber = (e: any) => {
    setNumber(e.target.value);
  }

  const onChangeFloor = (e: any) => {
    setFloor(e.target.value);
  }

  const onChangeDoor = (e: any) => {
    setDoor(e.target.value);
  }

  const onChangeCity = (e: any) => {
    setCity(e.target.value);
  }

  const onChangePostCode = (e: any) => {
    setPostCode(e.target.value);
  }

  const onChangeCountry = (e: any) => {
    setCountry(e.target.value);
  }

  const onChangeLunchIntolerances = (e: any) => {
    setLunchIntolerances(e.target.value);
  }

  const onChangeReceiptName = (e: any) => {
    setReceiptName(e.target.value);
  }

  const onChangeReceiptLastNames = (e: any) => {
    setReceiptLastNames(e.target.value);
  }

  const onChangeReceiptDni = (e: any) => {
    setReceiptDni(e.target.value);
  }

  const onChangeReceiptAddress = (e: any) => {
    setReceiptAddress(e.target.value);
  }

  const onChangeOralCommunication = (e: any) => {
    setPresentOralCommunication(e.target.checked);
  }

  const onChangeConsentData = (e: any) => {
    setConsentData(e.target.checked);
  }

  const onChangeAuthorizeImage = (e: any) => {
    setAuthorizeImage(e.target.checked);
  }

  const validate = (invalidSetMethod: (invalid: boolean) => void, invalid: boolean, y: number) => {
    invalidSetMethod(invalid);
    if(invalid) {
      invalidRef.current = true;
      if(y < invalidYRef.current) {
        invalidYRef.current = y;
      }
    }
  }

  const validateEmail = (y: number) => {
    if(!email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      setEmailInvalid(true);
      invalidRef.current = true;
      if(y < invalidYRef.current) {
        invalidYRef.current = y;
      }
    }
  }

  const onValidate = () => {
    invalidYRef.current = 1500;
    validate(setNameInvalid, !name, 325);
    validate(setLastNamesInvalid, !lastNames, 325);
    validate(setDniInvalid, !dni, 325);
    validate(setEmailInvalid, !email, 325);
    email && validateEmail(325);
    validate(setInstitution1Invalid, !institution1, 325);
    validate(setInstitution2Invalid, !institution2, 325);
    validate(setPositionInvalid, !position, 325);
    validate(setLocationInvalid, !street || !number || !city || !postCode, 325);
    lang === Lang.en && validate(setLocationInvalid, !country, 325);
    validate(setAssist26Invalid, assist26 === null, 810);
    validate(setAssist27Invalid, assist27 === null, 810);
    if(assist27) {
      validate(setLunchInvalid, lunch === null, 810);
      if(lunch) {
        validate(setNeedReceiptInvalid, needReceipt === null, 810);
        if(needReceipt) {
          validate(setReceiptNameInvalid, !receiptName, 810);
          validate(setReceiptLastNamesInvalid, !receiptLastNames, 810);
          validate(setReceiptDniInvalid, !receiptDni, 810);
          validate(setReceiptAddressInvalid, !receiptAddress, 810);
        }
      }
    }
    validate(setWorkshop27Invalid, assistWorkshop27 === null || (assistWorkshop27 === true && !workshop27), 980);
    validate(setWorkshop28Invalid, assistWorkshop28 === null || (assistWorkshop28 === true && !workshop28), 1070);
    validate(setConsentDataInvalid, !consentData, 1180);
    validate(setAuthorizeImageInvalid, !authorizeImage, 1180);
  }

  const onSave = async () => {
    invalidRef.current = false;
    onValidate();
    if(!invalidRef.current) {
      setLoading(true);
      const registrationService = serviceFactory.getRegistrationService();
      // validate email or dni already exist
      const exists = await registrationService.exists(email, dni);
      if(!exists) {
        const registration: Registration = {
          name: name,
          lastNames: lastNames,
          dni: dni,
          email: email,
          institution1: institution1,
          institution2: institution2,
          position: position,
          location: {
            street: street,
            number: number,
            floor: floor,
            door: door,
            city: city,
            postCode: postCode,
            country: country
          },
          presentOralCommunication: presentOralCommunication,
          presentExperience: false,
          assist26: !!assist26,
          assist27: !!assist27,
          lunch: !!lunch,
          lunchIntolerances: lunchIntolerances,
          needReceipt: !!needReceipt,
          assistWorkshop27: !!assistWorkshop27,
          assistWorkshop28: !!assistWorkshop28,
          lang: lang
        };
        if(needReceipt) {
          registration.receipt = {
            name: receiptName,
            lastNames: receiptLastNames,
            dni: receiptDni,
            address: receiptAddress
          };
        }
        if(workshop27) {
          let result = await fetch('https://laigualtatenjoc.cat/capacity/27');
          const capacity = await result.json();
          if(capacity[workshop27] <= 0) {
            setWorkshop27Invalid(true);
            setLoading(false);
            return;
          }
          registration.workshop27 = workshop27 as Workshop;
        }
        if(workshop28) {
          let result = await fetch('https://laigualtatenjoc.cat/capacity/28');
          const capacity = await result.json();
          if(capacity[workshop28] <= 0) {
            setWorkshop28Invalid(true);
            setLoading(false);
            return;
          }
          registration.workshop28 = workshop28 as Workshop;
        }
        console.log('registration', registration);
        await registrationService.create(registration);
        if(registration.lunch) {
          const url = 'https://laigualtatenjoc.cat/payment/' + registration.id;
          window.location.replace(url);
        }
        else {
          fetch('https://laigualtatenjoc.cat/email/' + registration.id)
            .then(() => {
              navigate('/success');
            });
        }
      }
      else {
        navigate('/duplicat');
      }
    }
    else {
      setLoading(false);
      console.log('position', invalidYRef.current);
      window.scrollTo({ top: invalidYRef.current }); 
    }
  }

  let mainTitle = 'Formulari d’inscripció';
  let section1Title = 'DADES PERSONALS';
  let section1Nom = 'NOM';
  let section1Cognoms = 'COGNOMS';
  let section1Dni = 'DOCUMENT IDENTIFICATIU (opció de DNI o PASSAPORT)';
  let section1Mail = 'CORREU ELECTRÒNIC';
  let section1Institution1 = 'INSTITUCIÓ 1  p.e. UNIVERSITAT /  INSTITUCIÓ / FEDERACIÓ / CLUB / EMPRESA';
  let section1Institution2 = 'INSTITUCIÓ 2  p.e. FACULTAT / DEPARTAMENT / DIVISIÓ';
  let section1Position = 'CÀRREC';
  let section1Location = 'ADREÇA POSTAL';
  let section1Street = 'CARRER';
  let section1Number = 'NÚMERO';
  let section1Floor = 'PIS';
  let section1City = 'POBLACIÓ';
  let section1PostCode = 'CP POBLACIÓ';
  let section1OralComunication = 'PRESENTO UNA COMUNICACIÓ ORAL (ESTUDI, EXPERIÈNCIA, ETC.)';
  let section2Title = 'PREVISIÓ D’ASSISTÈNCIA';
  let section2Subtitle = 'És obligatori marcar Sí o No en totes les opcions. L\'assistència al Congrés i als tallers és gratuïta';
  let section2Wed = 'DIMECRES 26';
  let section2Thu = 'DIJOUS 27 - MATÍ';
  let section2ThuLunch = 'Vols quedar-te a dinar al Congrés?*';
  let section2ThuIntolerances = 'INTOLERÀNCIES O NECESSITATS ESPECIALS ALIMENTÀRIES';
  let section2ThuTicket = '*El tiquet dinar té un cost de 15€, es pagarà en el moment que es completi la inscripció.';
  let section2ThuReceipt = 'Necessites rebut?';
  let section2ThuNom = 'NOM';
  let section2ThuCognoms = 'COGNOMS';
  let section2ThuDni = 'DNI / NIF';
  let section2ThuAddress = 'DIRECCIÓ';
  let section2ThuWorkshop = 'DIJOUS 27 - TARDA | Participaràs a algun taller?';
  let section2FriWorkshop = 'DIVENDRES 28 | Participaràs a algun taller?';
  let section3Text1 = 'Amb l\'enviament d\'aquest formulari consentiu que les vostres dades siguin utilitzades segons el Tractament d\'activitats de l\'INEFC.';
  let section3Text2 = 'Identificació del tractament: Activitats de l\'INEFC.';
  let section3Text3 = 'Responsable del tractament de les dades: Institut Nacional d\'Educació Física de Catalunya. Adreça electrònica de contacte: inefc.pd@gencat.cat';
  let section3Text4 = 'Finalitat: gestionar les activitats organitzades per l\'INEFC i aquelles en què participa preveient la comunicació amb les persones participants, la difusió de les activitats organitzades i la gestió de les invitacions a les persones ponents i col·laboradores.';
  let section3Text5 = 'Temps de conservació de les dades: les dades personals es conservaran durant el temps necessari per complir la finalitat per la qual es van recollir. Una vegada complerta aquesta finalitat, les dades s\'eliminaran d\'acord amb la normativa i els procediments vigents en matèria de gestió documental i arxivament.';
  let section3Text6 = 'Legitimació: el tractament s’efectua amb el consentiment de la persona interessada.';
  let section3Text7 = 'Destinataris: les dades no es comunicaran a altres categories de destinataris, excepte en els casos que preveu la llei.';
  let section3Text8 = 'Drets de les persones interessades i com es poden exercir: podeu trobar la informació relativa als drets de les persones interessades i com exercir-los en aquest enllaç:';
  let section3Text9 = 'Sí, consento l’ús de les meves dades segons el Tractament d\'activitats de l’INEFC';
  let section3Text10 = 'L\'INEFC disposa d\'un tractament de fons d\'imatges per a la promoció i difusió de les seves activitats. Per aquesta raó, el centre sol·licita el vostre consentiment per a la publicació de fotografies o filmacions en les quals aparegueu.';
  let section3Text11 = 'Identificació del tractament: Activitats de l\'INEFC.';
  let section3Text12 = 'Responsable del tractament de les dades: Institut Nacional d\'Educació Física de Catalunya. Adreça electrònica de contacte: inefc.pd@gencat.cat';
  let section3Text13 = 'Finalitat: disposar d\'un fons d\'imatges (fotografies i vídeos) relacionades amb les activitats acadèmiques, protocol·làries o de difusió de l\'INEFC. Gestionar, difondre, compartir i mantenir les imatges; utilitzar les imatges amb finalitats acadèmiques i per a la promoció de la institució.';
  let section3Text14 = 'Temps de conservació de les dades: la informació personal es conservarà durant el temps necessari per complir la finalitat per la qual es va recollir. Una vegada complerta aquesta finalitat, les dades s\'eliminaran d\'acord amb la normativa i els procediments vigents en matèria de gestió documental i arxivament.';
  let section3Text15 = 'Legitimació: el tractament s’efectua amb el consentiment de la persona interessada o bé en missió duta a terme d’interès públic.';
  let section3Text16 = 'Destinataris: les dades no es comunicaran a altres categories de destinataris, excepte en els casos que preveu la llei.';
  let section3Text17 = 'Drets de les persones interessades i com es poden exercir: podeu trobar la informació relativa als drets de les persones interessades i com exercir-los en aquest enllaç:';
  let section3Text18 = 'Sí, autoritzo l’ús de la meva imatge per a la finalitat indicada';
  let buttonLabel = 'INSCRIU-TE';
  if(lang === Lang.en) {
    mainTitle = 'Registration Form';
    section1Title = 'PERSONAL DETAILS';
    section1Nom = 'FIRST NAME';
    section1Cognoms = 'SURNAME(S)';
    section1Dni = 'IDENTITY DOCUMENT, either DNI (National Identity Document) or PASSPORT';
    section1Mail = 'EMAIL ADDRESS';
    section1Institution1 = 'INSTITUTION 1 e.g. UNIVERSITY/INSTITUTION/FEDERATION/CLUB/COMPANY';
    section1Institution2 = 'INSTITUTION 2 e.g. FACULTY/DEPARTMENT/DIVISION';
    section1Position = 'PROFESSIONAL POSITION';
    section1Location = 'MAILING ADDRESS';
    section1Street = 'STREET NAME';
    section1Number = 'NUMBER';
    section1Floor = 'FLOOR';
    section1City = 'TOWN/CITY ';
    section1PostCode = 'POSTCODE';
    section1OralComunication = 'I\'LL BE GIVING A TALK (AN EXPERIENCE...)';
    section2Title = 'ATTENDANCE';
    section2Subtitle = 'You must select Yes or No for each option. Attendance to the Congress and workshops is free of charge.';
    section2Wed = 'WEDNESDAY 26';
    section2Thu = 'THURSDAY 27 - MORNING';
    section2ThuLunch = 'Do you want to have lunch at the conference?*';
    section2ThuIntolerances = 'INTOLERANCES OR SPECIAL DIETARY NEEDS';
    section2ThuTicket = '*Lunch vouchers cost €15. Payment will be made upon completion of registration.';
    section2ThuReceipt = 'Do you need a receipt?';
    section2ThuNom = 'FIRST NAME';
    section2ThuCognoms = 'SURNAME(S)';
    section2ThuDni = 'DNI (National Identity Document) / NIF (Tax Identification Number)';
    section2ThuAddress = 'ADDRESS';
    section2ThuWorkshop = 'THURSDAY 27 - AFTERNOON | Will you participate in a workshop?';
    section2FriWorkshop = 'FRIDAY 28 | Will you participate in a workshop?';
    section3Text1 = 'By submitting this form, you consent to your data being used in accordance with the INEFC\'s Activity Processing.';
    section3Text2 = 'Identification of the processing: INEFC Activities.';
    section3Text3 = 'Data controller: Institut Nacional d\'Educació Física de Catalunya (National Institute of Physical Education of Catalonia). Email address of the contact: inefc.pd@gencat.cat';
    section3Text4 = 'Purpose: manage the activities organised by the INEFC and those in which it participates, providing for communication with participants, promotion of the activities organised and the sending of invitations to speakers and contributors.';
    section3Text5 = 'Data retention period: personal data shall be retained for as long as they are needed to fulfil the purpose for which they were collected. Once this purpose has been fulfilled, the data shall be deleted in accordance with the regulations and procedures in force regarding the management and filing of documents';
    section3Text6 = 'Legitimate basis: the processing is carried out with the consent of the data subject.';
    section3Text7 = 'Recipients: the data shall not be disclosed to other categories of recipients, except as provided for by law.';
    section3Text8 = 'Rights of data subjects and how they can be exercised: you can find information regarding the rights of data subjects and how to exercise them at this link:';
    section3Text9 = 'Yes, I consent to my data being used in accordance with the INFEC\'s Activity Processing.';
    section3Text10 = 'INEFC processes collections of images to promote and share its activities. As such, it is requesting your consent for the publishing of photographs or footage in which you may appear.';
    section3Text11 = 'Identification of the processing: INEFC Activities.';
    section3Text12 = 'Data controller: Institut Nacional d\'Educació Física de Catalunya (National Institute of Physical Education of Catalonia). Email address of the contact: inefc.pd@gencat.cat';
    section3Text13 = 'Purpose: have a collection of images (photographs and videos) related to the INEFC\'s academic, protocol-related and promotional activities. Manage, release, share and maintain the images; use said images for academic purposes and the promotion of the institution.';
    section3Text14 = 'Data retention period: personal information shall be retained for as long as it is needed to fulfil the purpose for which it was collected. Once this purpose has been fulfilled, the data shall be deleted in accordance with the regulations and procedures in force regarding the management and filing of documents.';
    section3Text15 = 'Legitimate basis: the processing is carried out with the consent of the data subject or in performing a task in the public interest.';
    section3Text16 = 'Recipients: the data shall not be disclosed to other categories of recipients, except as provided for by law.';
    section3Text17 = 'Rights of data subjects and how they can be exercised: you can find information regarding the rights of data subjects and how to exercise them at this link:';
    section3Text18 = 'Yes, I authorise the use of my image for the purpose indicated.';
    buttonLabel = 'REGISTER';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.formulari} />
      <h1 className="optionTitle">{mainTitle}</h1>
      <div className="formulari">
        <div className="block">
          <div className="title titleMb">{section1Title}</div>
          <div className="control">
            <div className={`label ${nameInvalid && 'invalid'}`}>{section1Nom}</div>
            <input type="text" className="inputText flexGrow1" onChange={onChangeName} />
            <div className={`label ml ${lastNamesInvalid && 'invalid'}`}>{section1Cognoms}</div>
            <input type="text" className="inputText flexGrow2" onChange={onChangeLastNames} />
          </div>
          <div className="control">
            <div className={`label ${dniInvalid && 'invalid'}`}>{section1Dni}</div>
            <input type="text" className="inputText flexGrow1" onChange={onChangeDni} />
            <div className={`label ml ${emailInvalid && 'invalid'}`}>{section1Mail}</div>
            <input type="text" className="inputText flexGrow2" onChange={onChangeEmail} />
          </div>
          <div className="control">
            <div className={`label ${institution1Invalid && 'invalid'}`}>{section1Institution1}</div>
            <input type="text" className="inputText flexGrow1" onChange={onChangeInstitution1} />
          </div>
          <div className="control">
            <div className={`label ${institution2Invalid && 'invalid'}`}>{section1Institution2}</div>
            <input type="text" className="inputText flexGrow1" onChange={onChangeInstitution2} />
          </div>
          <div className="control">
            <div className={`label ${positionInvalid && 'invalid'}`}>{section1Position}</div>
            <input type="text" className="inputText flexGrow1" onChange={onChangePosition} />
          </div>
          <div className="control">
            <div className={`label ${locationInvalid && 'invalid'}`}>{section1Location}</div>
            <input type="text" className="inputText flexGrow3" placeholder={section1Street} onChange={onChangeStreet} />
            <input type="text" className="inputText flexGrow1 ml" placeholder={section1Number} onChange={onChangeNumber} />
            <input type="text" className="inputText flexGrow1 ml" placeholder={section1Floor} onChange={onChangeFloor} />
            {lang === Lang.cat && <input type="text" className="inputText flexGrow1 ml" placeholder={'PORTA'} onChange={onChangeDoor} />}
            <input type="text" className="inputText flexGrow2 ml" placeholder={section1City} onChange={onChangeCity} />
            <input type="text" className="inputText flexGrow1 ml" placeholder={section1PostCode} onChange={onChangePostCode} />
            {lang === Lang.en && <input type="text" className="inputText flexGrow1 ml" placeholder={'COUNTRY'} onChange={onChangeCountry} />}
          </div>
          <span className="control flex">
            <input type="checkbox" className="inputCheckbox" onChange={onChangeOralCommunication} />
            <div className="label">{section1OralComunication}</div>
          </span>
        </div>
        <div className="devider"></div>
        <div className="block">
          <div className="title">{section2Title}</div>
          <div className="subtitle titleMb">{section2Subtitle}</div>
          <div className={`label bold ${assist26Invalid && 'invalid'}`}>{section2Wed}</div>
          <YesNo lang={lang} onChange={(value: boolean) => setAssist26(value)} />
          <div className={`label bold itemMt ${assist27Invalid && 'invalid'}`}>{section2Thu}</div>
          <YesNo lang={lang} onChange={(value: boolean) => setAssist27(value)} />
          {assist27 && <>
            <div className={`label bold ${lunchInvalid && 'invalid'}`}>{section2ThuLunch}</div>
            <YesNo lang={lang} onChange={(value: boolean) => setLunch(value)} />
            {lunch && <>
              <div className="control inputMt">
                <input type="text" className="inputText flexGrow1" placeholder={section2ThuIntolerances} onChange={onChangeLunchIntolerances} />
              </div>
              <div className="subtitle">{section2ThuTicket}</div>
              <div className={`label itemHalfMt ${needReceiptInvalid && 'invalid'}`}>{section2ThuReceipt}</div>
              <YesNo lang={lang} onChange={(value: boolean) => setNeedReceipt(value)} />
              {needReceipt && <>
                <div className="control itemHalfMt">
                  <div className={`label ${receiptNameInvalid && 'invalid'}`}>{section2ThuNom}</div>
                  <input type="text" className="inputText flexGrow1" onChange={onChangeReceiptName} />
                  <div className={`label ml ${receiptLastNamesInvalid && 'invalid'}`}>{section2ThuCognoms}</div>
                  <input type="text" className="inputText flexGrow2" onChange={onChangeReceiptLastNames} />
                </div>
                <div className="control">
                  <div className={`label ${receiptDniInvalid && 'invalid'}`}>{section2ThuDni}</div>
                  <input type="text" className="inputText flexGrow1" onChange={onChangeReceiptDni} />
                  <div className={`label ml ${receiptAddressInvalid && 'invalid'}`}>{section2ThuAddress}</div>
                  <input type="text" className="inputText flexGrow3" onChange={onChangeReceiptAddress} />
                </div>
              </>}
            </>}
          </>}
          <WorkshopForm lang={lang} title={section2ThuWorkshop} day={'27'} unavailable={workshop28} invalid={workshop27Invalid} onAssist={(value: boolean) => setAssistWorkshop27(value)} onChange={(workshop: string) => setWorkshop27(workshop)} />
          <WorkshopForm lang={lang} title={section2FriWorkshop} day={'28'} unavailable={workshop27} invalid={workshop28Invalid} onAssist={(value: boolean) => setAssistWorkshop28(value)} onChange={(workshop: string) => setWorkshop28(workshop)} />
        </div>
        <div className="devider"></div>
        <div className="block">
          <div className="subtitle subtitleMb">{section3Text1}</div>
          <div className="subtitle subtitleMb">{section3Text2}</div>
          <div className="subtitle subtitleMb">{section3Text3}</div>
          <div className="subtitle subtitleMb">{section3Text4}</div>
          <div className="subtitle subtitleMb">{section3Text5}</div>
          <div className="subtitle subtitleMb">{section3Text6}</div>
          <div className="subtitle subtitleMb">{section3Text7}</div>
          <div className="subtitle subtitleMb">{section3Text8}<br />https://inefc.gencat.cat/ca/inefc/proteccio-dades/drets-persones-interessades/</div>
          <span className="control subtitleMb flex">
            <input type="checkbox" className="inputCheckbox" onChange={onChangeConsentData} />
            <div className={`subtitle ${consentDataInvalid && 'invalid'}`} style={{ paddingTop: '6px'}}>{section3Text9}</div>
          </span>
          <div className="subtitle itemMt subtitleMb">{section3Text10}</div>
          <div className="subtitle subtitleMb">{section3Text11}</div>
          <div className="subtitle subtitleMb">{section3Text12}</div>
          <div className="subtitle subtitleMb">{section3Text13}</div>
          <div className="subtitle subtitleMb">{section3Text14}</div>
          <div className="subtitle subtitleMb">{section3Text15}</div>
          <div className="subtitle subtitleMb">{section3Text16}</div>
          <div className="subtitle subtitleMb">{section3Text17}<br />https://inefc.gencat.cat/ca/inefc/proteccio-dades/drets-persones-interessades/</div>
          <span className="control flex">
            <input type="checkbox" className="inputCheckbox" onChange={onChangeAuthorizeImage} />
            <div className={`subtitle ${authorizeImageInvalid && 'invalid'}`} style={{ paddingTop: '6px'}}>{section3Text18}</div>
          </span>
          <div className="buttonContainer">
            <button type="button" onClick={onSave} disabled={loading}>{buttonLabel}</button>
          </div>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string,
  serviceFactory: IServiceFactory
}

export default Formulari;