import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Recursos = ({ lang }: Props) => {
  let title = 'Recursos per a xarxes socials';
  let first = 'Cartell';
  let second = 'Cartell 1:1';
  let third = 'Espot 1080 x 1920';
  if(lang === Lang.en) {
    title = 'Resources for social network';
    first = 'Poster';
    second = 'Poster 1:1';
    third = 'Spot 1080 x 1920';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.recursos} />
      <div className="recursos">
        <h1 className="optionTitle">{title}</h1>
        <div className="grid">
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgCartell"></div>
              <a href="/content/AF_CARTELL_LIEJ_CAT.pdf" download={'AF_CARTELL_LIEJ_CAT.pdf'}>
                <div className="gridBoxLabel">{first}</div>
              </a>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgCartell11"></div>
              <a href="/content/1200x1200_CARTELL_CAT.jpg" download={'1200x1200_CARTELL_CAT.jpg'}>
                <div className="gridBoxLabel">{second}</div>
              </a>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgSpot"></div>
              <a href="/content/Spot_LIEJ_vertical.mp4" download={'Spot_LIEJ_vertical.mp4'}>
                <div className="gridBoxLabel">{third}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Recursos;