import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import './styles/styles.scss';
import Home from './pages/Home';
import ComunicacionsOrals from './pages/ComunicacionsOrals';
import Organitzacio from './pages/Organitzacio';
import { MenuOptions } from './model/MenuOptions';
import Footer from './components/Footer';
import Event from './pages/Event';
import Spot from './pages/Spot';
import Benvinguda from './pages/Benvinguda';
import SalaPremsa from './pages/SalaPremsa';
import Logotips from './pages/Logotips';
import Formulari from './pages/Formulari';
import { IServiceFactory } from './services/IServiceFactory';
import Success from './pages/Success';
import Error from './pages/Error';
import Programacio from './pages/Programacio';
import Export from './pages/Export';
import Duplicat from './pages/Duplicat';
import Recursos from './pages/Recursos';
import RecullPremsa from './pages/RecullPremsa';
import Finalitzat from './pages/Finalitzat';
import Fotografies from './pages/Fotografies';
import NotaPremsa from './pages/NotaPremsa';
import LlibreResums from './pages/LlibreResums';
import Conclusions from './pages/Conclusions';
import Galeria from './pages/Galeria';

const App = ({ serviceFactory }: Props) => {
  const Layout = () => {
    return (
      <>
        <div className="container">
          <div className="innerContainer">
            <Outlet />
            <Footer />
          </div>
        </div>
      </>
    );
  }

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const lang = searchParams.get('lang');
  return (
    <>
      <div className="background"></div>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home lang={lang || 'cat' } />} />
            <Route path={MenuOptions.benvinguda.link} element={<Benvinguda lang={lang || 'cat' } />} />
            <Route path={MenuOptions.organitzacio.link} element={<Organitzacio lang={lang || 'cat' } />} />
            <Route path={MenuOptions.salaPremsa.link} element={<SalaPremsa lang={lang || 'cat' } />} />
            <Route path={MenuOptions.spot.link} element={<Spot lang={lang || 'cat' } />} />
            <Route path={MenuOptions.logotips.link} element={<Logotips lang={lang || 'cat' } />} />
            <Route path={MenuOptions.recursos.link} element={<Recursos lang={lang || 'cat' } />} />
            <Route path={MenuOptions.recull.link} element={<RecullPremsa lang={lang || 'cat' } />} />
            <Route path={MenuOptions.fotografies.link} element={<Fotografies lang={lang || 'cat' } />} />
            <Route path={MenuOptions.nota.link} element={<NotaPremsa lang={lang || 'cat' } />} />
            <Route path={MenuOptions.llibreResums.link} element={<LlibreResums lang={lang || 'cat' } />} />
            <Route path={MenuOptions.conclusions.link} element={<Conclusions lang={lang || 'cat' } />} />
            <Route path={MenuOptions.programacio.link} element={<Programacio lang={lang || 'cat' } serviceFactory={serviceFactory} />} />
            <Route path={MenuOptions.formulari.link} element={<Finalitzat lang={lang || 'cat' } />} />
            <Route path={MenuOptions.success.link} element={<Success lang={lang || 'cat' } />} />
            <Route path={`${MenuOptions.success.link}/:date/:order/:code`} element={<Success lang={lang || 'cat' } />} />
            <Route path={MenuOptions.error.link} element={<Error lang={lang || 'cat' } />} />
            <Route path={MenuOptions.export.link} element={<Export serviceFactory={serviceFactory} />} />
            <Route path={MenuOptions.duplicat.link} element={<Duplicat lang={lang || 'cat' } />} />
            <Route path={MenuOptions.inscripcio.link} element={<Formulari lang={lang || 'cat' } serviceFactory={serviceFactory} />} />
            <Route path={MenuOptions.galeria.link} element={<Galeria lang={lang || 'cat' } />} />
          </Route>
          <Route path="/event" element={<Event />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

interface Props {
  serviceFactory: IServiceFactory
}

export default App;