import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const RecullPremsa = ({ lang }: Props) => {
  let title = 'Recull de premsa';
  if(lang === Lang.en) {
    title = 'Press releases';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.recull} />
      <div className="recull">
        <h1 className="optionTitle">{title}</h1>
        <a className="link" href="https://t.sidekickopen04-eu1.com/s3t/c/5/f18dQhb0V1-gm88fD6kFVlTMjD59hl2VN1Mk_njHV8VTW3GZHxF20Z1t2N1Q5FMC7Wy1Wf9fc6cz02?te=W3R5hFj4cm2zwW4mKLS-3ZSzQhW41S8zJ49K45GW1JxwY51LFZXFW3yMLvf41S-_RW1GHbH043Whb7W1S1nmt1N5xJkW1L949p1V1BYMW1LxLk-3K8QDQW43Wg3r41S_ZGW1Gy-qY3P5VTQW43myvB4hCtsWW3zhlVj43NXT1W4kB17H3zgwY1W3_Ymln4cN5znW3K7-Pz4fGvkjW3K6Kvb3T1kRNW3Cf8YP3R5h120&si=8000000027751431&pi=4526cf1e-e279-461c-bfbf-0ff2fecf7385" target={'_blank'}>
          20/03/23 - La Vanguardia
        </a>
        <a className="link" href="https://www.lavanguardia.com/vida/20230424/8918131/barcelona-acollira-nou-congres-per-igualtat-l-esport-26-28-d-abril.amp.html?fbclid=PAAaYqgAChE8txX6s7R65iS_nQ_Ew7959qbQJOZwC6DgtOOWn1BpPZuBAY9yk" target={'_blank'}>
          24/04/23 - La Vanguardia
        </a>
        <a className="link" href="/content/Sport-25-04-2023.pdf" download={'Sport-25-04-2023.pdf'}>
          25/04/23 - Sport
        </a>
        <a className="link" href="/content/L'esportiu-25-04-2023.pdf" download={'L\'esportiu-25-04-2023.pdf'}>
          25/04/23 - L'esportiu
        </a>
        <a className="link" href="https://govern.cat/gov/transformacio-feminista/502302/consellera-vilagra-l-esport-femeni-temps-que-hi-es-des-del-govern-ho-visible" target={'_blank'}>
          26/04/23 - Conferencia Inaugural - govern.cat
        </a>
        <a className="link" href="/content/Sport-27-04-2023.pdf" download={'Sport-27-04-2023.pdf'}>
          27/04/23 - Sport
        </a>
        <a className="link" href="/content/Segre-27-04-2023.pdf" download={'Segre-27-04-2023.pdf'}>
          27/04/23 - Segre
        </a>
        <a className="link" href="/content/Segre-28-04-2023.pdf" download={'Segre-28-04-2023.pdf'}>
          28/04/23 - Segre
        </a>
        <a className="link" href="/content/Segre-29-04-2023.pdf" download={'Segre-29-04-2023.pdf'}>
          29/04/23 - Segre
        </a>
        <a className="link" href="/content/Sport-29-04-2023.pdf" download={'Sport-29-04-2023.pdf'}>
          29/04/23 - Sport
        </a>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default RecullPremsa;