import React from 'react';
import { Lang } from '../model/Lang';

const LanguageMenu = ({ lang }: Props) => {
  const onChange = (lang: string) => {
    location.replace('?lang=' + lang);
  }

  return (
    <div className="language">
      <div className={`${lang === Lang.cat && 'selected'}`} onClick={() => onChange(Lang.cat)}>CAT</div>
      &nbsp;—&nbsp; 
      <div className={`${lang === Lang.en && 'selected'}`} onClick={() => onChange(Lang.en)}>ENG</div>
    </div>
  );
}

interface Props {
  lang: string
}

export default LanguageMenu;