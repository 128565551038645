import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Finalitzat = ({ lang }: Props) => {
  let title = 'Període d’inscripcions finalitzat.';
  let subtitle = 'Per a més informació contacteu amb la secretaria';
  if(lang === Lang.en) {
    title = 'The registration period has ended.';
    subtitle = 'For more information, please contact the secretary\'s office:';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.formulari} />
      <div className="message">
        <div className="devider"></div>
        <div className="title wide">{title}</div>
        <div className="subtitle wide">
          {subtitle}<br />
          <a href="mailto:congres.inefc@gencat.cat">congres.inefc@gencat.cat</a>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Finalitzat;