import React from 'react';
import { useParams } from 'react-router-dom';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Success = ({ lang }: Props) => {
  const params = useParams();
  let title = 'Moltes gràcies per inscriure\'t en el Congrés La Igualtat en Joc.';
  let subtitle = 'Comprova que has rebut el mail de confirmació correctament.';
  let left1 = 'Número comerç';
  let left2 = 'Data i hora';
  let left3 = 'Nom comerç';
  let left4 = 'URL comerç';
  let left5 = 'Import operació';
  let left6 = 'Codi d’autorització de redsys';
  let left7 = 'Descripció';
  let description = 'Tiquet per dinar';
  if(lang === Lang.en) {
    title = 'Thank you for registering for the La Igualtat en Joc (\'Equality on the Move\') Congress.';
    subtitle = 'Make sure you received the confirmation email.';
    left1 = 'Business number';
    left2 = 'Date and time';
    left3 = 'Business name';
    left4 = 'Business URL';
    left5 = 'Transaction amount';
    left6 = 'Redsys authorisation code';
    left7 = 'Description';
    description = 'Lunch ticket';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.success} />
      <div className="message">
        <div className="devider"></div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        {params.date && <div className="info">
          <div className="left">
            <div>{left1}:</div>
            <div>{left2}:</div>
            <div>{left3}:</div>
            <div>{left4}:</div>
            <div>{left5}:</div>
            <div>{left6}:</div>
            <div>{left7}:</div>
          </div>
          <div className="right">
            <div>357774025</div>
            <div>{params.date}</div>
            <div>La igualtat en Joc</div>
            <div>www.laigualtatenjoc.cat</div>
            <div>15€</div>
            <div>{params.code}</div>
            <div>{description}</div>
          </div>
        </div>}
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Success;