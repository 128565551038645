import { collection, query, getDocs, where } from 'firebase/firestore';
import { ModelService } from './ModelService';
import { Registration } from '../../model/Registration';
import { IRegistrationService } from '../IRegistrationService';

export class RegistrationService extends ModelService<Registration> implements IRegistrationService {
  async exists(email: string, dni: string): Promise<boolean> {
    const ref = collection(this.database, this.path);
    let docs = await getDocs(query(ref, where('email', '==', email)));
    if(docs.size > 0) {
      return true;
    }
    docs = await getDocs(query(ref, where('dni', '==', dni)));
    return docs.size > 0;
  }
}