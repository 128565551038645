import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Logotips = ({ lang }: Props) => {
  let title = 'Logotips';
  let first = 'Logotip positiu';
  let second = 'Logotip negatiu';
  let third = 'Isotip positiu';
  let fourth = 'Isotip negatiu';
  if(lang === Lang.en) {
    title = 'Logos';
    first = 'Color positive';
    second = 'Color reverse';
    third = 'Color positive';
    fourth = 'Color reverse';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.salaPremsa} />
      <div className="logotips">
        <h1 className="optionTitle">{title}</h1>
        <div className="grid">
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgLogotipPositiu"></div>
              <div className="gridBoxLabel">{first}</div>
              <a href="/content/LOGOTIP_POSITIU.ai" download={'LOGOTIP_POSITIU.ai'}><div className="gridBoxButton">.ai</div></a>
              <a href="/content/LOGOTIP_POSITIU.eps" download={'LOGOTIP_POSITIU.eps'}><div className="gridBoxButton">.eps</div></a>
              <a href="/content/LOGOTIP_POSITIU.pdf" download={'LOGOTIP_POSITIU.pdf'}><div className="gridBoxButton">.pdf</div></a>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgLogotipNegatiu"></div>
              <div className="gridBoxLabel">{second}</div>
              <a href="/content/LOGOTIP_NEGATIU.ai" download={'LOGOTIP_NEGATIU.ai'}><div className="gridBoxButton">.ai</div></a>
              <a href="/content/LOGOTIP_NEGATIU.eps" download={'LOGOTIP_NEGATIU.eps'}><div className="gridBoxButton">.eps</div></a>
              <a href="/content/LOGOTIP_NEGATIU.pdf" download={'LOGOTIP_NEGATIU.pdf'}><div className="gridBoxButton">.pdf</div></a>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgIsotipPositiu"></div>
              <div className="gridBoxLabel">{third}</div>
              <a href="/content/ISOTIP_POSITIU.ai" download={'ISOTIP_POSITIU.ai'}><div className="gridBoxButton">.ai</div></a>
              <a href="/content/ISOTIP_POSITIU.eps" download={'ISOTIP_POSITIU.eps'}><div className="gridBoxButton">.eps</div></a>
              <a href="/content/ISOTIP_POSITIU.pdf" download={'ISOTIP_POSITIU.pdf'}><div className="gridBoxButton">.pdf</div></a>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgIsotipNegatiu"></div>
              <div className="gridBoxLabel">{fourth}</div>
              <a href="/content/ISOTIP_NEGATIU.ai" download={'ISOTIP_NEGATIU.ai'}><div className="gridBoxButton">.ai</div></a>
              <a href="/content/ISOTIP_NEGATIU.eps" download={'ISOTIP_NEGATIU.eps'}><div className="gridBoxButton">.eps</div></a>
              <a href="/content/ISOTIP_NEGATIU.pdf" download={'ISOTIP_NEGATIU.pdf'}><div className="gridBoxButton">.pdf</div></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Logotips;