import React from 'react';

const Box = ({ title, className, children }: Props) => {
  return (
    <div className={`box ${className || ''}`}>
      <div className="title">{title}</div>
      <div className="content">
        { children }
      </div>
    </div>
  );
}

interface Props {
  title: string,
  className?: string,
  children: JSX.Element
}

export default Box;