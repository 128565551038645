import React from 'react';
import { Lang } from '../model/Lang';

const Opening = ({ lang }: Props) => {
  let title = 'Acte inaugural';
  let subtitle = 'Benvinguda institucional';
  let location = 'Sala d’Actes';
  let text1 = 'Actuació';
  let text2 = 'Presentadora';
  if(lang === Lang.en) {
    title = 'Opening';
    subtitle = 'Institutional Welcome';
    location = 'Auditorium';
    text1 = 'Performance';
    text2 = 'Presenter'
  }
  return (
    <div className="event opening">
      <div className={`title ${lang === Lang.en  && 'medItal'}`}>{title}</div>
      <div className={`title ${lang === Lang.en  && 'medItal'}`}>{subtitle}</div>
      <div className="speaker">
        <div className="text">Hble. Sra. Laura Vilagrà i Pons</div>
        <div className="text speakerPosition">Consellera de la Presidència</div>
      </div>
      <div className="text">{text1}: Mujercitas Crew</div>
      <div className="text">{text2}: Mireia Vicente</div>
      <div className="text">({location})<sup>*TS</sup></div>
    </div>
  );
}

interface Props {
  lang: string
}

export { Opening }