export interface Option {
  label: string,
  labelEn: string,
  link: string
}

export const MenuOptions = {
  inici: {
    label: 'Inici',
    labelEn: 'Home',
    link: '/'
  },
  benvinguda: {
    label: 'Benvinguda',
    labelEn: 'Welcome',
    link: '/benvinguda'
  },
  comunicacionsOrals: {
    label: 'Comunicacions orals',
    labelEn: 'Oral Presentations',
    link: '/comunicacions-orals'
  },
  organitzacio: {
    label: 'Organització',
    labelEn: 'Organization',
    link: '/organitzacio'
  },
  salaPremsa: {
    label: 'Sala de premsa',
    labelEn: 'Press Room',
    link: '/sala-premsa'
  },
  logotips: {
    label: 'Logotips',
    labelEn: 'Logos',
    link: '/logotips'
  },
  spot: {
    label: 'Spot',
    labelEn: 'Spot',
    link: '/spot'
  },
  recursos: {
    label: 'Recursos',
    labelEn: 'Resources',
    link: '/recursos'
  },
  recull: {
    label: 'Recull de premsa',
    labelEn: 'Press compilation',
    link: '/recull-premsa'
  },
  fotografies: {
    label: 'Fotografies',
    labelEn: 'Photos',
    link: '/fotografies'
  },
  nota: {
    label: 'Nota de premsa',
    labelEn: 'Press note',
    link: '/nota'
  },
  llibreResums: {
    label: 'Publicacions',
    labelEn: 'Publications',
    link: '/publications'
  },
  formulari: {
    label: 'Inscripció',
    labelEn: 'Registration',
    link: '/formulari'
  },
  programacio: {
    label: 'Programa',
    labelEn: 'Program',
    link: '/programacio'
  },
  success: {
    label: 'Success',
    labelEn: 'Success',
    link: '/success'
  },
  error: {
    label: 'Error',
    labelEn: 'Error',
    link: '/error'
  },
  export: {
    label: 'Export',
    labelEn: 'Export',
    link: '/export'
  },
  duplicat: {
    label: 'Duplicat',
    labelEn: 'Duplicate',
    link: '/duplicat'
  },
  inscripcio: {
    label: 'Inscripció',
    labelEn: 'Registration',
    link: '/inscripcio-privat'
  },
  conclusions: {
    label: 'Conclusions',
    labelEn: 'Conclusions',
    link: '/conclusions'
  },
  galeria: {
    label: 'Galeria Fotogràfica',
    labelEn: 'Photo Gallery',
    link: '/galeria'
  }
}