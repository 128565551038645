import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const NotaPremsa = ({ lang }: Props) => {
  let title = 'Nota de premsa';
  let resum = 'Resum';
  let finalNote = 'Nota de premsa final';
  let conclusions = 'Conclusions del Congrés';
  if(lang === Lang.en) {
    title = 'Press note';
    resum = 'Summary';
    finalNote = 'Final press note';
    conclusions = 'Conclusions of the Congress';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.nota} />
      <div className="recull">
        <h1 className="optionTitle">{title}</h1>
        <a className="link" href="/content/Nota_de_premsa_LIEJ.pdf" download={'Nota_de_premsa_LIEJ.pdf'}>{title}</a>
        <a className="link" href="/content/INEFC_notadepremsa_LaIgualtatenJoc_Primerajornada.pdf" download={'INEFC_notadepremsa_LaIgualtatenJoc_Primerajornada.pdf'}>{resum} 26/04/23</a>
        <a className="link" href="/content/INEFC_notadepremsa_LaIgualtatenJoc_Segonajornada.pdf" download={'INEFC_notadepremsa_LaIgualtatenJoc_Segonajornada.pdf'}>{resum} 27/04/23</a>
        <a className="link" href="/content/INEFC_notadepremsa_LaIgualtatenJoc_Tercerajornada.pdf" download={'INEFC_notadepremsa_LaIgualtatenJoc_Tercerajornada.pdf'}>{resum} 28/04/23</a>
        <a className="link" href="/content/INEFC_notadepremsa_LaIgualtatenJoc_Final.pdf" download={'INEFC_notadepremsa_LaIgualtatenJoc_Final.pdf'}>{finalNote}</a>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default NotaPremsa;