import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <div className="logo"></div>
      <div className="socialMedia">
        <a href="https://www.google.com/maps/place/Club+Esportiu+INEF+Barcelona/@41.3651179,2.1471405,17z/data=!4m6!3m5!1s0x12a4a275cf7a9ee1:0x83b2c71b80ac4b92!8m2!3d41.3651848!4d2.1471534!16s/g/1q5bmnhxj?hl=es" target="_blank">
          <div className="item map"></div>
        </a>
        <a href="https://twitter.com/laigualtatenjoc" target="_blank">
          <div className="item twitter"></div>
        </a>
        <a href="https://www.instagram.com/laigualtatenjoc/" target="_blank">
          <div className="item instagram"></div>
        </a>
        <a href="mailto:congres.inefc@gencat.cat" target="_blank">
          <div className="item mail"></div>
        </a>
      </div>
    </div>
  );
}

export default Footer;