import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import App from './App';
import { ServiceFactory } from './services/firebase/ServiceFactory';
import sdkConfig from './config/sdkConfig.json';

const container = ReactDOM.createRoot(document.getElementById('app'));
const url = new URL(window.location);
const searchParams = new URLSearchParams(url.search);
const lang = searchParams.get('lang');
const app = initializeApp(sdkConfig);
const serviceFactory = new ServiceFactory(app);
container.render(<App lang={lang || 'cat'} serviceFactory={serviceFactory} />);