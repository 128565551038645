import { Event } from '../../model/Event'
import { Lang } from '../../model/Lang';
import { IEventService } from '../IEventService';

export class EventService implements IEventService {
  private events = new Map<string, Event>();
  private lang: Lang;

  constructor() {
    this.lang = Lang.cat;
    this.setEvents();
  }

  get(id: string): Event | undefined {
    return this.events.get(id);
  }

  setLang(lang: Lang) {
    this.lang = lang;
    this.events.clear();
    this.setEvents();
  }

  private setEvents() {
    this.events.set('acteInaugural', {
      title: this.lang === Lang.cat ? 'Acte inaugural' : 'Opening ceremony',
      location: this.lang === Lang.cat ? 'Sala d’Actes' : 'Auditorium',
      speakers: [],
      isWorkshop: false
    });
    this.events.set('ponenciaI', {
      title: this.lang === Lang.cat ? 'Conferència inaugural' : 'Opening speech',
      subtitle: this.lang === Lang.cat ? 'La igualtat de gènere en joc: premem l’accelerador!' : 'Gender Equality on the Move: Accelerate!',
      location: this.lang === Lang.cat ? 'Sala d’Actes' : 'Auditorium',
      translated: true,
      speakers:  [{
        name: 'Johanna Adriaanse',
        image: 'JOHANNA_ADRIAANSE.png',
        position: this.lang === Lang.cat ? 'Universitat Tecnològica de Sydney' : 'Sydney University of Technology',
        companyLink: 'https://profiles.uts.edu.au/Johanna.Adriaanse',
        bio: this.lang === Lang.cat ? [
          'Experta reconeguda mundialment en l’àmbit de l’esport, la dona i la igualtat de gènere. Va iniciar la seva carrera com a professora d’Educació Física i Esport en escoles dels Països Baixos, Itàlia i Austràlia. L’any 1991 es va incorporar a la Universitat Tecnològica de Sydney (UTS) com a professora de Gestió Esportiva i també col·labora com a assessora internacional amb Uk Sport, Londres.',
          'La seva tasca acadèmica a la UTS durant més de 30 anys s’ha centrat en l’educació, la recerca i la promoció. Va ser copresidenta del Grup de Treball Internacional sobre Dones i Esport (2006-2014). L’any 2010, va promoure la 5a Conferència Mundial sobre Dones i Esport a Sydney i va encapçalar l’organisme que en va resultar, el Sydney Scoreboard, una eina per supervisar i accelerar el lideratge de les dones en l’esport. Va representar els Països Baixos a la Copa d’Europa d\'Hoquei i va formar part de l’equip que va guanyar 4 medalles d’or als anys setanta.',
          'Els caps de setmana se la pot trobar fàcilment navegant pel port de Sydney.'
        ] : [
          'She is a globally acknowledged expert in sport, women and gender equality. Currently, she is a Professional Fellow in Sport Management at the University of Technology Sydney (UTS) and an International Advisor at UK Sport, London.', 
          'As an academic at UTS for more than 30 years, her work has focused on education, research and advocacy. She is a former Co-chair of the International Working Group on Women and Sport (IWG, 2006-2014), convened the 5th World Conference on Women and Sport in Sydney in 2010 and led its legacy, the Sydney Scoreboard, a tool to monitor and accelerate women in sport leadership. Representing the Netherlands in the Europe Cup for Hockey, she was a member of the team that won 4 gold medals in the 1970s.',
          'Nowadays in weekends, you can often find her sailing on Sydney Harbour.'
        ]
      }],
      moderator: 'Anna Vilanova',
      isWorkshop: false
    });
    this.events.set('dialegI', {
      title: this.lang === Lang.cat ? 'Diàleg I' : 'Dialogue I',
      subtitle: this.lang === Lang.cat ? 'Dones en el lideratge esportiu: reptes i beneficis' : 'Women in sports leadership: challenges and benefits',
      location: this.lang === Lang.cat ? 'Sala d’Actes' : 'Auditorium',
      translated: true,
      speakers:  [
        {
          name: 'Glòria Balagué Gea',
          image: 'GLORIA_BALAGUE.png',
          position: this.lang === Lang.cat ? 'Universitat d’Illinois' : 'University of Illinois',
          companyLink: 'https://www.uic.edu/',
          bio: this.lang === Lang.cat ? [
            'Va iniciar la seva trajectòria professional al Centre de Recerca Medicoesportiva de la Residència Blume de Barcelona i va ser professora de l\'INEF d\'aquesta ciutat. El 1981 es va traslladar a Chicago i va obtenir un doctorat en Psicologia Clínica i Social a la Universitat d\'Illinois. Ha estat professora de Psicologia a la Universitat d\'Illinois a Chicago.',
            'Ha treballat amb les Federacions Nacionals dels Estats Units d\'Atletisme, Gimnàstica i Hoquei sobre Herba i ha acompanyat equips als Jocs Olímpics de 1992 i 1996. També ha estat directora dels serveis de Psicologia de l\'Esport de la Federació EUA d\'Atletisme i de Gimnàstica Rítmica, així com psicòloga de l\'equip professional de futbol americà de Chicago, els Chicago Bears. Cofundadora de TPSR Alliance (tpsr-alliance.org), un grup que promou l\'ús de l\'esport i l\'activitat física com a eina per millorar la responsabilitat personal i social dels joves.',
            'Ha estat presidenta de la Divisió 47 (Div. de Psicologia de l\'Exercici i l\'Esport) de l\'American Psychological Association (APA) i també de la Divisió 12 (Psicologia de l\'Esport) de l\'Associació Internacional de Psicologia Aplicada, així com la primera presidenta de l’Associació Catalana de Psicologia de l’Esport.'
          ] : [
            'She began her professional career at the Medical and Sports Research Centre at the Residència Blume in Barcelona and was a lecturer at the INEF there. In 1981 she moved to Chicago and obtained a doctorate in Clinical and Social Psychology at the University of Illinois. She has been a professor of Psychology at the University of Illinois at Chicago.',
            'She has worked with the US National Federations of Athletics, Gymnastics, and Field Hockey and has assisted the teams to the 1992 and 1996 Olympic Games. She has also been the director of the Sport Psychology services of the USA Athletics and Rhythmic Gymnastics Federations, as well as a psychologist for the Chicago Bears professional football team. Co-founder of the TPSR Alliance (tpsr-alliance.org), a group that promotes the use of sport and physical activity as a way to improve the personal and social responsibility of young people.',
            'She has been president of Division 47 (Exercise and Sport Psychology Division) of the American Psychological Association (APA) and also of Division 12 (Sport Psychology) of the International Association of Applied Psychology, as well as the first president of the Catalan Association of Sport Psychology.'
          ]
        },
        {
          name: 'Laura Burton',
          image: 'LAURA_BURTON.png',
          position: this.lang === Lang.cat ? 'Universitat de Connecticut' : 'University of Connecticut',
          companyLink: 'https://education.uconn.edu/person/laura-burton/',
          bio: this.lang === Lang.cat ? [
            'Professora de Gestió Esportiva i cap del Departament de Lideratge Educatiu a la Neag School of Education de la Universitat de Connecticut. La seva recerca ha anat encaminada a entendre els mecanismes de lideratge en les organitzacions, especialment les esportives, i explorar el desenvolupament, l’accés i l’èxit en el lideratge. La seva feina està centrada en qüestions de gènere en contextos de lideratge i, concretament, com els estereotips i la discriminació afecten les dones en el lideratge esportiu.'
          ] : [
            'Laura Burton is a professor of sport management and department head in the department of educational leadership in the Neag School of Education at the University of Connecticut. Her research interests include understanding leadership in organizations, particularly sport organizations, and exploring development, access, and success in leadership. In her work, she focuses on issues of gender in leadership contexts and, specifically, how stereotypes and discrimination affect women in sport leadership.'
          ]
        }
      ],
      moderator: 'Carlota Torrents',
      isWorkshop: false
    });
    this.events.set('dialegII', {
      title: this.lang === Lang.cat ? 'Diàleg II' : 'Dialogue II',
      subtitle: this.lang === Lang.cat ? 'Educar per a la igualtat des de l’EF i l’esport' : 'Educating for equality in PE and sports',
      location: this.lang === Lang.cat ? 'Aula Magna 1' : 'Aula Magna 1',
      speakers:  [
        {
          name: 'Cristina López Villar',
          image: 'CRISTINA_LOPEZ.png',
          position: this.lang === Lang.cat ? 'Universitat de la Corunya' : 'University of A Coruña',
          companyLink: 'https://www.udc.es/es/goberno/equipo_reitoral/vicd/',
          bio: this.lang === Lang.cat ? [
            'Doctora en Ciències de l’Activitat Física i de l’Esport (Universitat de Vigo). Llicenciada en Educació Física (Institut Nacional d’Educació Física de Galícia). Postgrau en Promoció i Educació per a la Salut (Universitat de Lleida) i Màster en Dansa i Moviment Teràpia (Universitat Autònoma de Barcelona). Actualment és vicerectora d’Igualtat, Cultura i Esport a la Universitat de la Corunya i professora a la Facultat de Ciències de l’Esport i l’Educació Física. Forma part de l’equip de recerca Educación, Salud y Actividad Física: Estudios de Género (ESAFEX), i de la Xarxa internacional de recerca en història de les dones i l’esport.',
            'Ha participat en diferents projectes relacionats amb el feminisme i l’esport. Entre altres publicacions, ha coordinat el llibre <span className="ital">Pioneiras do deporte en Galicia</span> (2017). Recentment ha format part del projecte d’R+D+I “Relaciones de género en educación secundaria. Estrategias, a través de la actividad físico-deportiva, para promover interacciones de equidad libres de violencia”.'
          ] : [
          'PhD in Physical Activity and Sport Sciences (University of Vigo). Degree in Physical Education (National Institute of Physical Education of Galicia). Postgraduate degree in Health Promotion and Education (University of Lleida) and Master’s Degree in Dance and Movement Therapy (Autonomous University of Barcelona). She is currently Vice Rector for Equality, Culture, and Sport at the University of A Coruña and lecturer at the Faculty of Sport Science and Physical Education. She is a member of the research team "Education, health and physical activity: gender studies” (ESAFEX) and of the research network on the history of women and sports.',
          'She has participated in different projects related to feminism and sport. Among other publications, she has written the book <span className="ital">Pioneiras do deporte en Galicia</span>. She has recently taken part in the R&D and innovation project "Gender Relations in Secondary Education. Strategies, through physical and sporting activity, to promote equitable interactions free of violence”.'
          ]
        },
        {
          name: 'Sandra Molines Borrás',
          image: 'SANDRA_MOLINES.png',
          position: this.lang === Lang.cat ? 'Florida Universitària' : 'Florida Universitària',
          companyLink: 'https://diversidad.florida.es/sandra-molines/',
          bio: this.lang === Lang.cat ? [
            'Llicenciada i doctora en Psicologia. Professora en els graus de Mestre/a en Educació Infantil i Primària a Florida Universitària, València. Docent en diferents màsters de la Universitat Jaume I i la Universitat de València. Professora col·laboradora a la UNED, a la VIU i a la Facultat de Ciències de l’Activitat Física i l’Esport de València. Des del 2007, consultora en la realització de plans d’igualtat en empreses i, des del 2015, en el desenvolupament de plans coeducatius de centres educatius.',
            'Ha treballat en projectes d’investigació-acció com a investigadora principal per a diversos ajuntaments. Codirectora del documental “Patis vius, patis coeducatius”, que va ser distingit per la Conselleria d’Habitatge, Obres Públiques i Vertebració de la Generalitat Valenciana, i del documental “Estime que t’estime”. Codirectora del projecte “Alerta convivència” sobre la prevenció de l’assetjament escolar per a la Diputació d’Educació de València. Al 2022, responsable de la inclusió de la perspectiva de gènere per a la Diputació d’Educació de València amb el projecte “Formosíssima”. Codirectora del projecte Mislata Consilia i Xirivella Concilia.',
            'Membre del projecte “Equidad y diversidad en Educación: Percepciones del alumnado Universitario respecto de la diversidad afectiva-sexual y de género”. Membre del grup de recerca emergent del projecte d’R+D+I AUCO. Ponent en diferents jornades, seminaris i congressos. Membre del grup de recerca de Diversitat a Florida Universitària i del Grup de Recerca de la Universitat de València: Educació i Diversitat LGTBIQ+ (EDi).'
          ] : [
            'PhD in Psychology. Lecturer in the Master\'s Degree in Early Childhood and Primary Education at Florida Universitària, Valencia. She is a teacher in several masters at the University Jaume I (Castelló) and the University of Valencia. She is also an assistant lecturer at UNED, VIU and the Faculty of Physical Activity and Sport Sciences of Valencia. Since 2007, she has been a consultant in the development of equality plans in companies and, since 2015, in the development of co-educational plans in educational centres.',
            'She has worked on research-action projects as a main researcher for various municipalities. She is the co-director in the documentary <span className="ital">"Patis vius, patis coeducatius"</span>, which was awarded by the <span className="ital">Conselleria d\'Habitatge, Obres Públiques i Vertebració de la Generalitat Valenciana</span>, and in the documentary <span className="ital">"Estime que t\'estime"</span>, as well as co-director of the project <span className="ital">"Alerta convivència"</span> on the prevention of school bullying for Valencia’s Education Ministry. In 2022, she was in charge of the inclusion of the gender perspective for the Valencia’s Education Ministry with the project <span className="ital">"Formosíssima"</span>. She was also a co-director of the <span className="ital">Mislata Consilia</span> and <span className="ital">Xirivella Concilia</span> projects.',
            'Member of the project "Equity and diversity in Education: Perceptions of University students regarding affective-sexual and gender diversity". Member of the emerging research group of the AUCO R&D and innovation project. Speaker at different conferences, seminars and congresses. Member of the Diversity research group at Florida Universitària and the University of Valencia Research Group: Education and LGTBIQ+ Diversity (EDi).'
          ]
        }
      ],
      moderator: 'Dolors Ribalta',
      isWorkshop: false
    });
    this.events.set('dialegIII', {
      title: this.lang === Lang.cat ? 'Diàleg III' : 'Dialogue III',
      subtitle: this.lang === Lang.cat ? 'El paper dels mitjans de comunicació: on són les dones?' : 'The role of media: where are the women?',
      location: this.lang === Lang.cat ? 'Aula Magna 2' : 'Aula Magna 2',
      speakers:  [
        {
          name: 'Susana Guerrero Salazar',
          image: 'SUSANA_GUERRERO.png',
          position: this.lang === Lang.cat ? 'Universitat de Màlaga' : 'University of Malaga',
          companyLink: 'https://www.uma.es/departamento-de-filologia-espanola/info/85727/susana-guerrero-salazar/',
          bio: this.lang === Lang.cat ? [
            'Catedràtica de Llengua Espanyola de la Universitat de Màlaga. Premi de l’Instituto Andaluz de la Mujer en la categoria de coeducació (2014). XX Premi nacional d’assaig Leonor de Guzmán pel llibre <span className="ital">La prensa deportiva española; sexismo lingüístico y discursivo</span> (2017). Ha rebut un reconeixement internacional a la Cimera Mundial del Coneixement de 2022. Directora del projecte <a href="https://dismupren.com" target="_blank">DISMUPREN</a>.',
            'Reconeguda internacionalment pels seus estudis sobre anàlisi del discurs amb perspectiva de gènere. Imparteix habitualment conferències i cursos de formació a Europa i Hispanoamèrica sobre l’ús no sexista del llenguatge i de la imatge en els mitjans de comunicació. És autora de més de cent publicacions en llibres i revistes de primer nivell, així com de diverses guies d’ús no sexista del llenguatge i de les imatges, entre d’altres la de la Universitat de Màlaga, la de la Universitat de Jaén, la de la Diputació de Huelva i la de l’Instituto Andaluz de la Mujer, amb el títol “¿Piensas como hablas?”.'
          ] : [
            'A professor of Spanish Language at the University of Malaga. She has been awarded by the Andalusian Women Institute in the category of co-education (2014) and with the XX Leonor de Guzmán National Essay Prize for the book <span className="ital">La prensa deportiva española: sexismo lingüístico y discursivo</span> (2017). She received international recognition at the World Knowledge Summit 2022. She is the director of the <a href="https://dismupren.com" target="_blank">DISMUPREN</a> project.',
            'She has been internationally recognized for her studies on discourse analysis with a gender perspective and she regularly gives conferences and training courses in Europe and Latin America on the non-sexist use of language and images in the media. She is the author of more than one hundred articles in books and leading journals. She is the author of several guides on the non-sexist use of language and images, including the University of Málaga, the University of Jaén, the Huelva Provincial Council, and the guide for the Andalusian Women Institute entitled <span className="ital">¿Piensas como hablas?</span>.'
          ]
        },
        {
          name: 'Maria Guixà Solé',
          image: 'MARIA_GUIXA.png',
          position: this.lang === Lang.cat ? 'Catalunya Ràdio' : 'Catalunya Ràdio',
          companyLink: 'https://www.ccma.cat/catradio/maria-guixa/autor/8050/',
          bio: this.lang === Lang.cat ? [
            'Exatleta i periodista. Treballa al departament d’esports de Catalunya Ràdio des del 2009. Actualment és l’editora i presentadora del programa “Catalunya Migdia Esports Cap de Setmana” i la coordinadora de “Tot Gira”. Ha cobert els Jocs Olímpics de Tòquio, cinc mundials de natació, així com diversos campionats d’àmbit europeu relacionats amb l’atletisme, la natació i el waterpolo. Premi Dona i Esport de l’Ajuntament de Barcelona.'
          ] : [
            'She is a journalist and a former athlete. She has been working in the sports department of Catalunya Ràdio since 2009. She is currently the editor and presenter of the radio show <span className="ital">Catalunya Migdia Esports Cap de Setmana</span> and the coordinator of <span className="ital">Tot Gira</span>. She has covered the Olympic Games in Tokyo, five swimming world championships, as well as several European championships related to athletics, swimming and water polo. The Barcelona City Council awarded her with the <span className="ital">Dona i Esport</span> recognition.'
          ]
        }
      ],
      moderator: 'Montse Martin',
      isWorkshop: false
    });
    this.events.set('comunicacionsOralsI', {
      title: this.lang === Lang.cat ? 'Comunicacions orals i presentació d’experiències' : 'Oral communications and presentation of experiences',
      subtitle: this.lang === Lang.cat ? 'Sessió I' : 'Session I',
      translated: true,
      speakers: [],
      moderator: '',
      isWorkshop: false
    });
    this.events.set('ponenciaII', {
      title: this.lang === Lang.cat ? 'Bones pràctiques en la promoció de la igualtat de gènere en les organitzacions esportives' : 'Good Practices Promoting Gender Equality in Sport Organizations',
      location: this.lang === Lang.cat ? 'Sala d’Actes': 'Auditorium',
      translated: true,
      speakers:  [
        {
          name: 'Marijke Fleuren',
          image: 'MARIJKE_FLEUREN.png',
          position: this.lang === Lang.cat ? 'Federació Europea d’Hoquei' : 'European Hockey Federation',
          companyLink: 'https://eurohockey.org/executive-board/',
          bio: this.lang === Lang.cat ? [
            'Presidenta de la Federació Europea d’Hoquei i membre del Consell Executiu de la Federació Internacional d’Hoquei (FIH), després d\'anys de participar en l’elaboració de polítiques a escala nacional i de clubs. Dins de la FIH, presideix el Comitè “Dones en l’Esport”. Des de 2015 fins a finals de 2022, va ser membre de la Comissió “Dones en l’Esport” del COI i el desembre de 2020 va ser nomenada experta en Igualtat de Gènere en l’Esport en el Grup d’Alt Nivell (GAN) de la Comissió Europea.',
            'A Europa, destaca en temes de bona governança, diversitat i inclusió, amb especial atenció en la igualtat d’oportunitats entre homes i dones. Un dels seus missatges clau és demostrar que tot el món pot marcar la diferència.'
          ] : [
            'She is the President of the European Hockey Federation and Executive Board Member of the FIH, after many years of being involved in policy making at the national and club level. Within the FIH she chairs the ‘Women in Sports’ Committee. From 2015 till the end of 2022, she was a member of the IOC Commission ‘Women in Sport’ and in December 2020 she was appointed as an expert in Gender Equality in Sport in the High-Level Group (HLG) of the European Commission.',
            'She is one of the most prevalent voices in Europe on Good Governance, Diversity and Inclusion with special attention for Equal Opportunities for Women and Men. Proving that everybody can make a difference is one of her key messages.'
          ]
        }
      ],
      moderator: 'Marta Moragas',
      isWorkshop: false
    });
    this.events.set('dialegIV', {
      title: this.lang === Lang.cat ? 'Diàleg IV': 'Dialogue IV',
      subtitle: this.lang === Lang.cat ? 'Esport, adolescència i gènere': 'Sport, adolescence and gender',
      location: this.lang === Lang.cat ? 'Sala d’Actes': 'Auditorium',
      translated: true,
      speakers:  [
        {
          name: 'Kety Balibrea Melero',
          image: 'KETY_BALIBREA.png',
          position: this.lang === Lang.cat ? 'Universitat Politècnica de València' : 'Polytechnic University of Valencia',
          companyLink: 'https://www.uv.es/dis/miembros.htm',
          bio: this.lang === Lang.cat ? [
            'Doctora en Ciències de l’Activitat Física i l’Esport. Actualment és membre de la Càtedra d’Estudis Esportius de la Universitat Politècnica de València. Forma part de l’Observatori Valencià de l’Esport de la Comunitat Valenciana. Entre les seves publicacions més destacades figuren els llibres <span className="ital">Deporte, mujer y exclusión social: experiencias europeas de inserción por el deporte</span> (2004) i <span className="ital">Deporte en los barrios: ¿integración o control social?</span> (2011).',
            'Les seves àrees d’interès se centren en l’estudi de l’activitat física i l’esport aplicat a poblacions socialment desafavorides i les seves problemàtiques. Ha fet el seu treball de recerca de tercer cicle sobre “Activitat física, esport i inserció juvenil: una aproximació a la intervenció social en zones urbanes desafavorides” (2000).'
          ] : [
            'PhD in Physical Activity and Sports Sciences. She is currently a member of the Chair of Sport Studies at the Polytechnic University of Valencia. She is a member of the Valencia Sports Observatory. Among her most outstanding publications are the books <span className="ital">Deporte, mujer y exclusión social: experiencias europeas de inserción por el deporte</span> (2004) and <span className="ital">Deporte en los barrios: ¿integración o control social?</span> (2011).',
            'Her areas of interest focus on the study of physical activity and sports applied to socially disadvantaged groups of population and their problems. She has done her third cycle of research work on "Physical activity, sport, and youth integration: an approach to social intervention in disadvantaged urban areas" (2000).'
          ]
        },
        {
          name: 'Fiona Dowling',
          image: 'FIONA_DOWLING.png',
          position: this.lang === Lang.cat ? 'Escola Noruega de Ciències de l’Esport' : 'Norwegian School of Sport Sciences',
          companyLink: 'https://www.nih.no/en/about/employees/fiona-jane-dowling/',
          bio: this.lang === Lang.cat ? [
            'Catedràtica d’Educació en el Departament d’Esport i Ciències Socials de l’Escola Noruega de Ciències de l’Esport, a Oslo. Els seus interessos de recerca inclouen la justícia social i la inclusió en contextos esportius i d’educació física, la igualtat de gènere i l’esport, l’antiracisme i l’esport, la professionalitat del professorat i la metodologia qualitativa (en particular, la indagació narrativa).',
            'Actualment, participa en un projecte de recerca sobre els drets dels infants en l’esport, així com en un projecte que investiga l’ús de la tecnologia intel·ligent per a l’activitat física i la salut entre la gent gran.'
          ] : [
            'She is a Professor of Education at the Department of Sport and Social Sciences at the Norwegian School of Sport Sciences in Oslo. Her research interests include social justice and inclusion in sport and Physical Education contexts, gender equality and sport, anti-racism and sport, teacher professionalism, and qualitative methodology (in particular, narrative inquiry).',
            'She is currently involved in a research project on Children’s Rights in sport, as well as a project investigating the use of smart technology for physical activity and health amongst the elderly.'
          ]
        }
      ],
      moderator: 'Pedrona Serra',
      isWorkshop: false
    });
    this.events.set('dialegV', {
      title: this.lang === Lang.cat ? 'Diàleg V' : 'Dialogue V',
      subtitle: this.lang === Lang.cat ? 'Polítiques esportives per a la igualtat' : 'Sports policies for equality',
      location: this.lang === Lang.cat ? 'Aula Magna 1' : 'Aula Magna 1',
      speakers:  [
        {
          name: 'Elvira Hidalgo Rodríguez',
          image: 'ELVIRA_HIDALGO.png',
          position: this.lang === Lang.cat ? 'Universitat de La Laguna, Tenerife' : 'University of La Laguna, Tenerife',
          companyLink: 'https://www.linkedin.com/in/elvira-hidalgo-rodriguez-a7a65760/?originalSubdomain=es',
          bio: this.lang === Lang.cat ? [
            'Tècnica superior d’Esports de la ULL (Universitat de La Laguna). Agent clau en el I i II METV (Marco estratégico en políticas de igualdad de género Tenerife Violeta) del Cabildo Insular de Tenerife. Component del Consell Canari de l’Esport. Va ser vicepresidenta de la Comissió MELL (Mujeres en la Lucha) de la Federació de Lluita Canària, així com d’ACAGEDE (Asociación Canaria de Profesionales de la Gestión del Deporte) i del COLEFC (Colegio Oficial de Licenciados en Educación Física y en Ciencias del Deporte de Canarias). Coordinadora i ponent en diferents fòrums, cursos i xerrades vinculades a la igualtat d’oportunitats entre dones i homes en i mitjançant l’esport.',
          ] : [
            'Senior Sports Technician at the University of La Laguna, she has been a key agent in the I and II METV (Marco Estratégico en Políticas de Igualdad de Género Tenerife Violeta) of the Cabildo Insular de Tenerife. Member of the Canary Islands Sport Council and former vice president of the MELL (Women in Wrestling) Commission, of the Canary Islands Wrestling Federation. Former vice president of ACAGEDE (Canary Islands Association of Sports Management Professionals). She is the former vice president of COLEFC (Official College of Graduates in Physical Education of the Canary Islands). She has participated both as a coordinator and speaker in different meetings, courses, and talks related to equal opportunities between women and men in and through sports.'
          ]
        },
        {
          name: 'Dorleta Ugalde Usandizaga',
          image: 'DORLETA_UGALDE.png',
          position: this.lang === Lang.cat ? 'Govern Basc' : 'Basque Government',
          companyLink: 'https://www.euskadi.eus/gobierno-vasco/inicio/',
          bio: this.lang === Lang.cat ? [
            'Llicenciada en Ciències de l’Activitat Física i de l’Esport. Postgrau en Activitats Físiques Adaptades per la Universitat Lliure de Brussel·les, Màster en Gestió d’Oci (especialitat en Gestió Esportiva) per la Universitat de Deusto, Màster en Igualtat de Dones i Homes per la UPV-EHU. És la responsable tècnica del Programa per a la promoció de la igualtat entre dones i homes en l’àmbit esportiu i del Programa d’esport escolar de la Direcció d’Activitat Física i Esport del Govern Basc.'
          ] : [
            'Graduate in Physical Activity and Sport Sciences. Postgraduate degree in Adapted Physical Activities from the Université Libre de Bruxelles. Master\'s Degree in Leisure Management —Specialising in Sports Management— from the University of Deusto. Master in Equality of Women and Men by the UPV-EHU. She is the technical manager of the Programme for the promotion of equality between women and men in sports and of the School Sports Programme of the Physical Activity and Sports Directorate of the Basque Government.'
          ]
        },
        {
          name: 'Maria Teresa Vizcarra Morales',
          image: 'MARIATE_VIZCARRA.png',
          position: this.lang === Lang.cat ? 'Universitat del País Basc (UPV/EHU)' : 'University of the Basque Country (UPV/EHU)',
          companyLink: 'https://www.ehu.eus/es/web/master/master-psicodidactica/profesorado-de-tfm?p_redirect=fichaPDI&p_idp=4172',
          bio: this.lang === Lang.cat ? [
            'Llicenciada en Educació Física i doctora en Filosofia i Ciències de l’Educació, té una trajectòria de més de 30 anys com a professora tant a secundària com a la universitat. En l’àmbit de la recerca, les seves investigacions s’han centrat en la pedagogia de l’activitat física i ha participat en estudis relacionats amb les habilitats socials i els valors educatius de l’esport escolar, la responsabilitat personal i social en l’activitat física, la implicació de les famílies en l’esport escolar, la inclusió de la perspectiva de gènere en l’activitat física i l’esport i l’expressió corporal. Ha participat també en projectes d’R+D+I centrats en la resolució de conflictes en l’educació física en comunitats d’aprenentatge, estudis amb perspectiva de gènere o metodologies docents.',
            'Fins el 2016 va participar en una investigació finançada pel MINECO on s’analitzava “la (no) presència de les dones en les assignatures dels graus d’activitat física i esportiva de diferents universitats”, amb Susanna Soler Prat com a investigadora principal. Actualment està dirigint dues tesis relacionades amb la visibilitat de les dones esportistes en els mitjans de comunicació i sis tesis doctorals més lligades a la formació del professorat. Les darreres investigacions han estat vinculades a l’aprenentatge-servei i, dins d\'aquest, s’estan treballant projectes lligats a la dramatització i la inclusió de la perspectiva de gènere.'
          ] : [
            'With a degree in Physical Education and a PhD in Philosophy and Educational Sciences, she has over 30 years of experience as a teacher both at secondary school and university. In the field of research, her work has focused on the pedagogy of physical activity, and she has participated in studies related to social skills and educational values in school sports, personal and social responsibility in physical activity, the involvement of families in school sports, the inclusion of the gender perspective in physical activity and sport and corporal expression. She has also participated in R&D and innovation projects focused on conflict resolution in physical education in learning communities, studies from a gender perspective, and teaching methodologies.',
            'Until 2016 she participated in research funded by MINECO on the analysis of "the (non) presence of women in the subjects of the physical activity and sports degrees in different universities", with Susanna Soler Prat as the main researcher. She is currently directing two theses related to the visibility of women athletes in the media and six doctoral theses related to teacher training. The latest research has been linked to service-learning and, within this, on projects linked to dramatization and the inclusion of the gender perspective.'
          ]
        }
      ],
      moderator: 'Joshua Muñoz',
      isWorkshop: false
    });
    this.events.set('dialegVI', {
      title: this.lang === Lang.cat ? 'Diàleg VI' : 'Dialogue VI',
      subtitle: this.lang === Lang.cat ? 'Promoció de l’esport femení: campanyes i patrocini' : 'Promoting women\'s sport: campaigns and sponsorship',
      location: this.lang === Lang.cat ? 'Aula Magna 2' : 'Aula Magna 2',
      speakers:  [
        {
          name: 'Gemma Cernuda Canelles',
          image: 'GEMMA_CERNUDA.png',
          position: this.lang === Lang.cat ? 'Ellas Deciden' : 'Ellas Deciden',
          companyLink: 'https://ellasdeciden.com/sobre-gemma-cernuda/',
          bio: this.lang === Lang.cat ? [
            'Màster en Gestió de la Comunicació per la Universitat de Barcelona (UB), titulada per la Columbia Business School NYC i becada en el programa “W50 Women on Board” a la UCLA (EUA). Ha dirigit la comunicació de marques líders com Congelats La Sirena. Ha estat la primera dona membre de diverses associacions empresarials com ara la patronal Cecot; ha estat la primera vicepresidenta de l’Associació Empresarial de Publicitat de Catalunya i és professora del Màster en Planificació Estratègica de la UAB.',
            'Ha estat seleccionada en tres ocasions com una de les Top 100 dones líders a Espanya. És la primera consultora de <span className="ital">marketing to women</span> a Espanya. La seva empresa, Ellas Deciden (2001), és la primera agència de comunicació en femení a Espanya.',
            'Conferenciant internacional, columnista, creadora de marques i d’opinió en fòrums i mitjans de comunicació. És autora del primer blog espanyol de comunicació en femení. Ha publicat vuit llibres, l’últim dels quals és <span className="ital">Atrapados en el feminismo</span> (2019). És la creadora del mètode GenderFilter i del Decálogo+1 de la comunicació en femení. Ha coinspirat i codirigit esdeveniments pioners a Barcelona, com ara RETHINK, Fòrum Dona i Esport o WomenZFestival. També és membre del patronat de la Fundació ARED, que ajuda les dones a tenir una segona oportunitat després del seu pas per la presó.',
            '“Competeixo en maratons perquè em donen força, determinació i voluntat per assolir els meus projectes i canviar l’status quo. GRÀCIES és la meva paraula preferida”.'
          ] : [
            'She holds a Master in Communication Management from Barcelona University (UB), an executive course in Managing Marketing from Columbia Business School in NYC and an Executive course from UCLA on Women on Boards #W50. She has managed the communication of leading brands such as Congelados La Sirena. She has been named the first female member of several Spanish Business Associations such as the employers association Cecot, a former vice president of the Advertising Association of Catalonia, and she is a lecturer on the Masters Course in Strategic Planning at the Barcelona Autonomous University (UAB).',
            'She has also been selected three times as one of The TOP 100 women leaders in Spain. She is the first Marketing to Women Consultant in Spain. Her company, Ellas Deciden (2001), is the first feminine branding agency in Spain.',
            'An international lecturer, columnist, branding and opinion maker in forums and the media. She is the author of the first blog on female branding in Spanish and she has published eight books, the latest one being <span className="ital">Atrapados en el feminismo</span> (2019). She is the creator of the method ©GenderFilter and of the ©Decalogo+1 de la Comunicación en Femenino. She has co-inspired and co-directed pioneering events in Barcelona such as RETHINK, Forum  Dona  i  Esport or WomenZFestival. She is also a member at the Board of ARED Foundation, which helps women to get a second chance in life after being in prison.',
            '“I also run marathons and this gives me the strength, determination and will to achieve my projects and change the status quo. THANK YOU is my favorite word.”'
          ]
        },
        {
          name: 'Maria Teixidor Jufresa',
          image: 'MARIA_TEIXIDOR.png',
          position: this.lang === Lang.cat ? 'Vuca Solutions, SL' : 'Vuca Solutions, SL',
          companyLink: 'https://vucasolutions.com/nosotros/',
          bio: this.lang === Lang.cat ? [
            'Advocada i mediadora, ha desenvolupat la seva carrera professional, que vehicula a través de Vuca Solutions, SL, en l’àmbit de l’assessorament a empreses i <span className="ital">start-ups</span>, fundacions, associacions i particulars del món de la cultura, la innovació, l’esport i la tecnologia, entre d’altres. Com a emprenedora en el sector del LegalTech, ha cofundat dues empreses emergents: Red Points (2011), contra la pirateria <span className="ital">online</span>, i BCN RESOL (2016), que proporciona canals d’avís a escolars i corporacions per notificar situacions d’assetjament i/o conflicte. Membre del Comitè Executiu de la PIMEC des de 2021, presideix la Comissió Dona i Empresa, on lidera l’elaboració d’un estudi sobre dona i economia a Espanya, a fi de formular indicadors per assegurar que els fons Next Generation s’apliquen, també, en clau de gènere.',
            'És vicepresidenta primera d’ONU Mujeres España, comitè nacional, des de setembre de 2022. Membre de la Junta Directiva del FC Barcelona entre 2015 i 2020, va ser la primera dona a ocupar el càrrec de secretària de l’òrgan de govern del Club, i una de les 13 que han format part del màxim òrgan de govern des de la seva fundació l’any 1899. Responsable del futbol femení i presidenta del Grup Edelmira Calvetó, va contribuir al creixement d’aquesta vessant del futbol i va donar visibilitat a la dona blaugrana, de qui en va recuperar la memòria històrica. Vicepresidenta i secretària del Patronat de la Fundació FC Barcelona (2015–2017), on va impulsar la firma del Pacte Mundial de les Nacions Unides, va coliderar el desenvolupament del pla estratègic de l’entitat i va introduir els programes de lluita contra l\'assetjament escolar. Va ocupar la Presidència del Circuit de Barcelona-Catalunya entre agost i setembre de 2020.'
          ] : [
            'A lawyer and mediator, she has developed her professional career, which she channels through Vuca Solutions, SL, in the field of advising companies and start-ups, foundations, associations, and individuals in the fields of culture, innovation, sport, and technology, among others. As an entrepreneur in the LegalTech sector, she has co-founded two start-ups: Red Points (2011), against online piracy, and BCN RESOL (2016), which provides help channels for schoolchildren and corporations to notify situations of danger and/or conflict. A member of the Executive Committee of PIMEC since 2021, she chairs the Commission Woman and Enterprise, where she is leading a study on women and economy in Spain, in order to formulate indicators to ensure that the Next Generation funds are also applied with a gender perspective.',
            'She is first vice president of UN Women Spain, National Committee, since September 2022. A member of the FC Barcelona Board of Directors between 2015 and 2020, she was the first woman to hold the position of Secretary of the Club\'s governing body, and one of the 13 women who have been part of the highest governing body since its foundation in 1899. As a responsible for women\'s football and president of the Edelmira Calvetó Group, she contributed to the growth of this area of football and gave visibility to women supporters, whose historical memory she revived. Vice president and secretary of the Board of Trustees of the FC Barcelona Foundation (2015-2017), she promoted the signing of the United Nations Global Compact, led the development of the organisation\'s strategic plan, and introduced programmes to fight school absenteeism. She held the Presidency of the Circuit Barcelona-Catalunya between August and September 2020.'
          ]
        }
      ],
      moderator: 'Jose Luís López del Amo',
      isWorkshop: false
    });
    this.events.set('taulaRodonda', {
      title: this.lang === Lang.cat ? 'Esportistes:' : 'Athletes:',
      subtitle: '',
      subsubtitle: this.lang === Lang.cat ? 'Helena Arias, Sílvia Bonastre, Aina Cid, Laura Heredia i Itziar Llobet' : 'Helena Arias, Sílvia Bonastre, Aina Cid, Laura Heredia & Itziar Llobet',
      location: this.lang === Lang.cat ? 'Sala d’Actes' : 'Auditorium',
      translated: true,
      speakers: [],
      moderator: 'Àlex Gozalbo',
      isWorkshop: false
    });
    this.events.set('tallerI', {
      title: this.lang === Lang.cat ? 'Taller I' : 'Workshop I',
      subtitle: this.lang === Lang.cat ? 'Masculinitats i esport' : 'Masculinities and sport',
      speakers:  [
        {
          name: 'Miquel Joan Far Ferrer',
          image: 'MIQUEL_JOAN_FERRER.png',
          position: this.lang === Lang.cat ? 'Universitat de les Illes Balears (UIB)' : 'University of Balearic Islands (UIB)',
          companyLink: 'https://www.uib.eu/personal/ABjE4Njc4MA/',
          bio: this.lang === Lang.cat ? [
            'Psicòleg social, psicòleg general sanitari i terapeuta familiar i de parella (FEATF). És professor de la Universitat de les Illes Balears (UIB) i membre del grup de recerca sobre igualtat de gènere de la UIB. Màster en Teràpia Breu Estratègica i en Resolució i Anàlisi de Conflictes. És expert en conflictes de gènere i en multiculturalitat, en masculinitats i en direcció i gestió de centres de serveis socials, així com formador de l’Escola Genèrica (Consell de Mallorca) i assessor sobre programes de coeducació de la Conselleria d’Educació i Innovació (Govern de Balears).',
            'També és col·laborador docent del Centre Kine de teràpia familiar i de parella i de l’Instituto Antántico de Terapia Familiar, i fundador i coordinador dels programes d’atenció psicològica de l’Associació EIMA.'
          ] : [
            'Social psychologist, general health psychologist and family and couple therapist (FEATF). He is a lecturer at the University of the Balearic Islands (UIB) and a member of the research group on gender equality at the UIB. He holds a master\'s degree in strategic Brief Therapy and in Conflict Resolution and Analysis. He is an expert in gender conflicts and multiculturalism, in masculinities and in the direction and management of social service centres, as well as a trainer at the Escola Genèrica (Consell de Mallorca) and an advisor on coeducation programmes for the Education and Innovation Ministry of the Balearic Government.',
            'He is also a teaching collaborator at the Kine Centre for family and couple therapy and the <span className="ital">Instituto Antántico de Terapia Familiar</span>, and founder and coordinator of the psychological care programmes of the EIMA Association.'
          ]
        }
      ],
      moderator: 'Sílvia Puigarnau',
      isWorkshop: true,
      location: 'Aula 1'
    });
    this.events.set('tallerII', {
      title: this.lang === Lang.cat ? 'Taller II' : 'Workshop II',
      subtitle: this.lang === Lang.cat ? 'Esport segur: prevenció de les violències sexuals' : 'Safe sport: the prevention of sexual violences',
      speakers:  [
        {
          name: 'Carla Vall i Duran',
          image: 'CARLA_VALL.png',
          position: this.lang === Lang.cat ? 'Advocada penalista i criminòloga' : 'Criminal lawyer and criminologist',
          companyLink: 'https://www.carlavall.com/',
          bio: this.lang === Lang.cat ? [
            'Advocada amb una trajectòria professional de més de 10 anys. Des del 2011 s’ha dedicat de manera continuada a formar-se com a advocada i criminòloga experta en dret penal i de gènere. Durant aquest temps també ha format altres professionals en el Centre d\'Estudis Jurídics i Formació Especialitzada, l’Observatori Català de la Justícia en Violència Masclista, l’Institut de Seguretat Pública de Catalunya, l’Institut Català de les Dones o l’Institut Canari d\'Igualtat, entre d’altres.',
            'Forma part del grup d\'expertes del Ministeri d\'Igualtat en relació amb les violències de gènere i sexuals. Assessora, també, en institucions públiques i organismes de caire estatal, autonòmic i local. És autora del llibre <span className="ital">Trenqueu en cas d’emergència: Manual per a víctimes i supervivents de violències masclistes</span> (Ed. Univers, 2022), amb pròleg de Paula Bonet.'
          ] : [
            'A lawyer with a professional career of more than 10 years. Since 2011 she has been continuously training as a lawyer and criminologist, specializing in criminal and gender law. During this time, she has also trained other professionals at the Centre d\'Estudis Jurídics i Formació Especialitzada, Observatori Català de la Justícia en Violència Masclista, Institut de Seguretat Pública de Catalunya, Institut Català de les Dones or Instituto Canario de Igualdad, among others.',
            'She is a member of the group of experts of the Ministry of Equality in the area of gender and sexual violence. She also advises public institutions and organisations at national, regional, and local levels. She is the author of the book <span className="ital">Trenqueu en cas d\'emergència: Manual per a víctimes i supervivents de violències masclistes</span> (Ed. Univers, 2022), with a foreword by Paula Bonet.'
          ]
        }
      ],
      moderator: 'Pedrona Serra',
      isWorkshop: true,
      location: 'Sala d\'Actes'
    });
    this.events.set('tallerIII', {
      title: this.lang === Lang.cat ? 'Taller III' : 'Workshop III',
      subtitle: this.lang === Lang.cat ? 'Comunicació per al canvi' : 'Communication for Change',
      speakers:  [
        {
          name: 'Clara Basiana Cañellas',
          image: 'CLARA_BASIANA.png',
          position: this.lang === Lang.cat ? 'Almena Cooperativa Feminista' : 'Almena Feminist Cooperative',
          companyLink: 'https://almenafeminista.org/es/',
          bio: this.lang === Lang.cat ? [
            'Periodista. Exmembre de l’equip olímpic de natació artística. Treballadora de Col·lectivaT i col·laboradora d’Almena Cooperativa Feminista. Membre de l’equip de redacció de la revista esportiva <span className="ital">Fosbury</span>. Acompanya entitats i projectes perquè puguin difondre la seva tasca i comunicar la realitat amb perspectiva de gènere i feminista.'
          ] : [
            'Journalist. Former member of the Olympic artistic swimming team. Employee of Col·lectivaT and collaborator of Almena Cooperativa Feminista. She is a member of the editorial team of the sports magazine <span className="ital">Fosbury</span>. She supports organizations and projects so that they can spread their work and communicate reality from a gender and feminist perspective.'
          ]
        }
      ],
      moderator: 'Raquel Font',
      isWorkshop: true,
      location: 'Aula 3'
    });
    this.events.set('tallerIV', {
      title: this.lang === Lang.cat ? 'Taller IV' : 'Workshop IV',
      subtitle: this.lang === Lang.cat ? 'Igualtat en l’esport des de la gestió esportiva local' : 'Equality in sport from the perspective of local sports management',
      speakers:  [
        {
          name: 'Ainhoa Azurmendi Echegaray',
          image: 'AINHOA_AZURMENDI.png',
          position: this.lang === Lang.cat ? 'Universitat del País Basc (UPV/EHU)' : 'University of the Basque Country (UPV/EHU)',
          companyLink: 'https://congresomujeresydeporte.es/speaker/ainhoa-azurmendi-echegaray',
          bio: this.lang === Lang.cat ? [
            'Consultora de planificació esportiva a Avento i professora associada a la Universitat del País Basc. Doctora en Psicologia. Màster en Igualtat de Dones i Homes. Especialista universitària en Psicologia de l’Activitat Física i de l’Esport. <span className="ital">Coach</span> esportiva (Federació Espanyola de Psicologia de l’Esport). Acreditació nacional com a psicòloga experta en Psicologia de l’Esport (Consell General de la Psicologia). Facilitadora <span className="ital">online</span> del programa FIFA Guardians per a la salvaguarda de menors en l’esport. Professora de suport per a l’alumnat FIFA de parla hispana (setembre 2021-actualitat).',
            'En la seva tasca com a consultora destaquen projectes desenvolupats en l’àmbit de l’esport amb l’objectiu de promoure la igualtat de dones i homes en aquest àmbit a escala autonòmica, estatal i europea, amb un contracte amb el Consell d’Europa per dissenyar indicadors de gènere en l’àmbit esportiu. Ha dut a terme treballs pioners en matèria de prevenció de la violència sexual en l’esport, com ara una guia específica per a l’Emakunde/Instituo Vasco de la Mujer el 2015, o un treball aplicat a la Reial Societat de Futbol SAD des de 2017. Ha fet la ponència marc en el I i II Congreso Estatal Mujeres y Deporte (2017 i 2019).',
            'Ha participat amb ponències en els darrers quatre dels vuit congressos mundials Dona i Esport: Austràlia (2010), Finlàndia (2014), Botswana (2018) i Nova Zelanda (2022, online). L’abril de 2022 va presentar la comunicació “Non-accidental violence prevention protocols in sport: practical approach in a professional football club”, en el 3r Congrés Global Safe Sport International.'
          ] : [
            'She is a sports planning consultant at Avento and an associate lecturer at the University of the Basque Country. PhD in Psychology. Master\'s degree in Equality between Women and Men. A university specialist in Psychology of Physical Activity and Sport. Sports Coach (Spanish Federation of Sports Psychology). She holds a national accreditation as an expert psychologist in Sports Psychology. She is also an online facilitator of the FIFA Guardians programme for the safeguarding of minors in sport. A support teacher for Spanish-speaking FIFA students (September 2021-present).',
            'Her work as a consultant includes projects developed in the field of sport to promote equality between men and women in this field at regional, national, and European levels, with a contract with the Council of Europe to design gender indicators in the field of sport. She has carried out pioneering work on the prevention of sexual violence in sports, such as a specific guide for Emakunde/Basque Women\'s Institute in 2015, and work applied to the Real Sociedad de Futbol SAD since 2017. She was the keynote speaker at the I and II State Congress on Women and Sport (2017 and 2019).',
            'She has presented papers in the last four of the eight World Congresses on Women and Sport: Australia (2010), Finland (2014), Botswana (2018), and New Zealand (2022, online). In April 2022 she presented the paper "Non-accidental violence prevention protocols in sport: a practical approach in a professional football club", at the 3rd Global Safe Sport International Congress.'
          ]
        }
      ],
      moderator: 'Raimon Recoder',
      isWorkshop: true,
      location: 'Aula 2'
    });
    this.events.set('tallerV', {
      title: this.lang === Lang.cat ? 'Taller V' : 'Workshop V',
      subtitle: this.lang === Lang.cat ? 'Com fer plans d’igualtat en les organitzacions esportives' : 'How to make equality plans in sports organizations',
      speakers:  [
        {
          name: 'Anna Rovira Puig',
          image: 'ANNA_ROVIRA.png',
          position: this.lang === Lang.cat ? 'Consultora. Advocada i criminòloga' : 'Consultant. Lawyer and criminologist',
          companyLink: 'https://es.linkedin.com/in/anna-rovira-puig-935136143',
          bio: this.lang === Lang.cat ? [
            'Graduada en Dret i Criminologia per la Universitat de Girona i màster oficial en Problemes Socials a la UNED. Actualment, la seva activitat professional està centrada en l’elaboració i el seguiment de projectes i programes d’intervenció per promoure la igualtat de gènere en i a través de l’esport i l’activitat física. Imparteix formació sobre prevenció, detecció i intervenció en casos de violències sexuals en l’àmbit esportiu i sobre com incorporar la perspectiva de gènere en les entitats esportives i en la gestió pública municipal. És col·laboradora del GISEAFE (Grup d’Investigació Social i Educativa de l’Activitat Física i l’Esport) de l’INEFC.'
          ] : [
            'A graduate in Law and Criminology from the University of Girona and official Master\'s Degree in Social Problems from the UNED. Currently, her professional activity focuses on the development and monitoring of projects and intervention programmes to promote gender equality in and through sport and physical activity. She provides training on prevention, detection and intervention in cases of sexual violence in the field of sport and on how to incorporate the gender perspective in sport entities and in municipal public management. She collaborates with GISEAFE (Social and Educational Research Group on Physical Activity and Sport) at INEFC.'
          ]
        }
      ],
      moderator: 'Raquel Mirabet',
      isWorkshop: true,
      location: 'Aula 4'
    });
    this.events.set('tallerVI', {
      title: this.lang === Lang.cat ? 'Taller VI' : 'Workshop VI',
      subtitle: this.lang === Lang.cat ? 'Esport i salut amb perspectiva de gènere' : 'Sport and health from a gender perspective',
      speakers:  [
        {
          name: 'Eva Ferrer Vidal-Barraquer',
          image: 'EVA_FERRER.png',
          position: this.lang === Lang.cat ? 'Hospital Sant Joan de Déu' : 'Hospital Sant Joan de Déu',
          companyLink: 'https://www.sjdhospitalbarcelona.org/ca/especialistes/eva-ferrer-vidal-barraquer',
          bio: this.lang === Lang.cat ? [
            'Metgessa especialista en medicina de l’esport, actualment treballa a la Unitat de Medicina de l’Esport Hospital Clínic-Sant Joan de Déu. Durant els últims quatre anys, ha estat la metgessa del primer equip de futbol femení del FC Barcelona, així com la responsable mèdica del futbol formatiu del FC Barcelona. Continua vinculada al club com a especialista en salut femenina i rendiment en el FC Barcelona Innovation Hub, on fa recerca en el camp de la monitorització del cicle menstrual i el rendiment de la futbolista.',
            'És membre de la Societat Catalana de Medicina de l\'Esport i de la Sociedad Española de Medicina del Deporte. També és integrant del grup de treball de salut i futbol femení de la UEFA.',
            'Ha publicat dos libres, <span className="ital">Canvia de vida, posa’t a córrer</span> i <span className="ital">Córrer sense lesionar-se és possible?</span>, i diversos articles en revistes científiques relacionats també amb la dona i l’esport. Ha fet de ponent en congressos i jornades nacionals i internacionals relacionats amb la salut de la dona i l’esport i imparteix docència en màsters de salut i esport.'
          ] : [
            'A specialist in sports medicine, she currently works at the Sports Medicine Unit of the Hospital Clínic-Sant Joan de Déu in Barcelona. For the last four years, she has been the doctor of FC Barcelona\'s first women\'s football team, as well as the medical director of FC Barcelona\'s training football. She is still linked to the club as a specialist in women\'s health and performance at the FC Barcelona Innovation Hub, where she conducts research in the field of menstrual cycle monitoring and footballer performance.',
            'She is a member of the Catalan Society of Sports Medicine and the Spanish Society of Sports Medicine. She is also a member of the UEFA working group on health and women\'s football.',
            'She has published two books, <span className="ital">Canvia de vida, posa\'t a córrer</span> and <span className="ital">Córrer sense lesionar-se és possible</span>, and several articles in scientific journals also related to women and sport. She has been a speaker at national and international congresses and conferences related to women\'s health and sport and a teacher in several master\'s degrees on health and sport.'
          ]
        }
      ],
      moderator: 'Silvia Aranda',
      isWorkshop: true,
      location: 'Aula Magna 5'
    });
    this.events.set('comunicacionsOralsII', {
      title: this.lang === Lang.cat ? 'Comunicacions orals i presentació d’experiències' : 'Oral communications and presentation of experiences',
      subtitle: this.lang === Lang.cat ? 'Sessió II' : 'Session II',
      speakers: [],
      moderator: '',
      isWorkshop: false
    });
    this.events.set('womenInCoaching', {
      title: this.lang === Lang.cat ? 'Les dones en l\'entrenament esportiu' : 'Women in Sport Coaching',
      location: this.lang === Lang.cat ? 'Sala d’Actes' : 'Auditorium',
      translated: true,
      speakers:  [
        {
          name: 'Nicole M. LaVoi',
          image: 'NICOLE_LAVOI.png',
          position: this.lang === Lang.cat ? 'Universitat de Minnesota' : 'University of Minnesota',
          companyLink: 'https://www.cehd.umn.edu/kin/people/nmlavoi/',
          bio: this.lang === Lang.cat ? [
            'Professora titular a l’Àrea de Ciències Socials i del Comportament de la Facultat de Cinesiologia de la Universitat de Minnesota i directora del Tucker Center for Reasearch on Girls & Women in Sport. A través de la seva recerca multidisciplinària, respon a preguntes essencials que poden marcar una gran diferència en la vida de les nenes i les dones.',
            'Com a destacada especialista en gènere, lideratge i dones entrenadores, ha publicat més de cent capítols de llibres, treballs de recerca i articles sobre diverses disciplines. El seu treball de recerca principal inclou l’informe anual <span className="ital">Women in College Coaching Report Card</span>, l’objectiu del qual és mantenir i fer créixer el nombre de dones en la professió d’entrenadores, i un llibre revolucionari, <span className="ital">Women in Sports Coaching</span> (2016). També ha fet col·laboracions en l’àmbit audiovisual, com ara el documental “Media Coverage & Female Athletes: Women Play Sports, Just Not in the Media (2013)”, guanyador d’un Emmy, i “GAME ON: Women can Coach” (2018).',
            'Com a acadèmica, ha creat un <span className="ital">podcast</span>, assessora diversos grups del sector, fa conferències arreu del món, col·labora amb mitjans de comunicació, ofereix lideratge i forma part de diversos consells assessors nacionals relacionats amb les nenes i les dones en l’esport. Va ser jugadora de tenis al Gustavus Adolphus College i va guanyar un Campionat Nacional per Equips NCAA-III. Actualment forma part del seu Consell d’Administració. També ha estat professora a l’Associació de Tennis Professional dels Estats Units (USPTA), segona entrenadora a Carleton College i primera entrenadora a Wellesley College.',
            'Li agrada fer activitats a l’aire lliure, anar en bicicleta, fer muntanyisme, jugar a golf i prendre el sol.'
          ] : [
            'She is a Senior Lecturer in the area of social and behavioral sciences in the School of Kinesiology at the University of Minnesota and the Director of the Tucker Center for Research on Girls & Women in Sport. Through her multidisciplinary research, she answers critical questions that can make a difference in the lives of sport stakeholders—particularly girls and women.',
            'As a leading scholar on gender, leadership and women coaches, Dr. LaVoi has published 100+ book chapters, research reports, and peer-reviewed articles across multiple disciplines. Her seminal research includes the annual <span className="ital">Women in College Coaching Report Card</span>™ which is aimed at retaining and increasing the number of women in the coaching profession and holding decision makers accountable, a groundbreaking book <span className="ital">Women in Sports Coaching</span> (2016), and a documentary <span className="ital">GAME ON: Women Can Coach</span> (2018). <span className="ital">GAME ON</span> is the third Emmy-nominated collaborative film project with tptMN; the first was <span className="ital">Concussion & Female Athletes</span> (2011) and the second, <span className="ital">Media Coverage & Female Athletes: Women Play Sports, Just Not in the Media</span> (2013) won a regional Emmy for best sports documentary.',
            'As a public scholar she hosts a podcast, consults with a variety of stakeholder groups, speaks frequently around the world, fields media requests, provides thought leadership, and serves on numerous mission-driven, national advisory boards related to girls and women in sport. LaVoi played collegiate tennis at Gustavus Adolphus College earning two-time Academic All-American status and an NCAA-III National Team Championship, and currently serves on the Board of Trustees. Prior to her career in the academy, she was a USPTA Teaching Pro, an assistant coach at Carleton College, and the head tennis coach at Wellesley College.', 
            'In her free time she enjoys being outdoors, biking, hiking, golf, and soaking up the sun.'
          ]
        }
      ],
      moderator: 'Ingrid Hinojosa',
      isWorkshop: false
    });
    this.events.set('conclusions', {
      title: this.lang === Lang.cat ? 'Conclusions i Debat final' : 'Conclusions and Final Debate',
      location: this.lang === Lang.cat ? 'Sala d’Actes' : 'Auditorium',
      translated: true,
      speakers: [],
      moderator: '',
      isWorkshop: false
    });
    this.events.set('cloenda', {
      title: this.lang === Lang.cat ? 'Cloenda institucional' : 'Institutional Closing',
      location: this.lang === Lang.cat ? 'Sala d’Actes': 'Auditorium',
      translated: true,
      speakers: [],
      moderator: '',
      isWorkshop: false
    });
  }
}