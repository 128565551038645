import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuOptions, Option } from '../model/MenuOptions';
import { Lang } from '../model/Lang';

const Menu = ({ lang, open, option }: Props) => {
  const [showOptions, setShowOptions] = useState(open);

  const getLink = (link: string) => {
    return link + '?lang=' + lang;
  }

  const onClick = () => {
    setShowOptions(!showOptions);
  }

  let iniciLabel = MenuOptions.inici.label;
  let benvingudaLabel = MenuOptions.benvinguda.label;
  let programacioLabel = MenuOptions.programacio.label;
  let organitzacioLabel = MenuOptions.organitzacio.label;
  let comunicacionsOralsLabel = MenuOptions.comunicacionsOrals.label;
  let formulariLabel = MenuOptions.formulari.label;
  let salaPremsaLabel = MenuOptions.salaPremsa.label;
  let llibreResumsLabel = MenuOptions.llibreResums.label;
  let galeriaLabel = MenuOptions.galeria.label;
  if(lang === Lang.en) {
    iniciLabel = MenuOptions.inici.labelEn;
    benvingudaLabel = MenuOptions.benvinguda.labelEn;
    programacioLabel = MenuOptions.programacio.labelEn;
    organitzacioLabel = MenuOptions.organitzacio.labelEn;
    comunicacionsOralsLabel = MenuOptions.comunicacionsOrals.labelEn;
    formulariLabel = MenuOptions.formulari.labelEn;
    salaPremsaLabel = MenuOptions.salaPremsa.labelEn;
    llibreResumsLabel = MenuOptions.llibreResums.labelEn;
    galeriaLabel = MenuOptions.galeria.labelEn;
  }

  return (
    <div className="menu" onClick={onClick}>
      {showOptions && <div className="menuOptions">
        <Link to={getLink(MenuOptions.inici.link)} className={`${option === MenuOptions.inici && 'selected'}`}>{iniciLabel}</Link>
        <Link to={getLink(MenuOptions.benvinguda.link)} className={`${option === MenuOptions.benvinguda && 'selected'}`}>{benvingudaLabel}</Link>
        <Link to={getLink(MenuOptions.organitzacio.link)} className={`${option === MenuOptions.organitzacio && 'selected'}`}>{organitzacioLabel}</Link>
        <Link to={getLink(MenuOptions.programacio.link)} className={`${option === MenuOptions.programacio && 'selected'}`}>{programacioLabel}</Link>
        <Link to={getLink(MenuOptions.formulari.link)} className={`${option === MenuOptions.formulari && 'selected'}`}>{formulariLabel}</Link>
        <Link to={getLink(MenuOptions.salaPremsa.link)} className={`${option === MenuOptions.salaPremsa && 'selected'}`}>{salaPremsaLabel}</Link>
        <Link to={getLink(MenuOptions.llibreResums.link)} className={`${option === MenuOptions.llibreResums && 'selected'}`}>{llibreResumsLabel}</Link>
        <Link to={getLink(MenuOptions.galeria.link)} className={`${option === MenuOptions.galeria && 'selected'}`}>{galeriaLabel}</Link>
      </div>}
    </div>
  );
}

interface Props {
  lang: string,
  open: boolean,
  option: Option
}

export default Menu;