import React, { useEffect, useState } from 'react';
import { google, ics, CalendarEvent } from 'calendar-link';
import { Lang } from '../model/Lang';
import LanguageMenu from '../components/LanguageMenu';
import Menu from '../components/Menu';
import { MenuOptions } from '../model/MenuOptions';

const Home = ({ lang }: Props) => {
  const [topHeaderText, setTopHeaderText] = useState('');
  const [bottomHeaderText, setBottomHeaderText] = useState('');
  const [mainText, setMainText] = useState('');
  const [downloadText, setDownloadText] = useState('');
  const [eventText, setEventText] = useState('');
  const [infoText, setInfoText] = useState('');
  const [event, setEvent] = useState('');
  const [link, setLink] = useState('');
  const [bottomTextTitle, setBottomTextTitle] = useState('');
  const [bottomTextTitleBold, setBottomTextTitleBold] = useState('');
  const [bottomTextP1, setBottomTextP1] = useState('');
  const [bottomTextP2, setBottomTextP2] = useState('');
  const [bottomTextP3, setBottomTextP3] = useState('');
  const [bottomTextP4, setBottomTextP4] = useState('');
  const [bottomTextP5, setBottomTextP5] = useState('');
  const [bottomTextBold, setBottomTextBold] = useState('');
  const [titleText, setTitleText] = useState('');
  const [dr, setDr] = useState('');
  
  useEffect(() => {
    const topHeaderTextCat = 'INEFC BARCELONA';
    const topHeaderTextEn = 'INEFC BARCELONA';
    const bottomHeaderTextCat = '26, 27 i 28 abril 2023';
    const bottomHeaderTextEn = 'From April 26 to 28';
    const mainTextCat = 'Un espai on promoure la igualtat real i efectiva entre dones i homes en l’àmbit de l’activitat física i l’esport a través de polítiques públiques amb perspectiva de gènere, mesures per a la igualtat en les entitats esportives i la visibilitat de l’esport femení.';
    const mainTextEn = 'A space to promote real and effective equality between women and men in the field of physical activity and sport through public policies with a gender perspective, measures for equality in sports entities and the visibility of women’s sport.';
    const downloadTextCat = 'DESCARREGA’T EL PROGRAMA';
    const downloadTextEn = 'DOWNLOAD THE PROGRAM';
    const addEventTextCat = 'RESERVA LA DATA';
    const addEventTextEn = 'SAVE THE DATE';
    const infoTextCat = 'INSCRIPCIONS OBERTES A PARTIR DEL 15 DE MARÇ';
    const infoTextEn = 'REGISTRATION OPENS ON MARCH 15TH';
    const linkCat = 'programa/LIEJ_programa_cat.pdf';
    const linkEn = 'programa/LIEJ_programme_ang.pdf';
    const bottomTextTitleCat = 'Congrés';
    const bottomTextTitleEn = 'Congress';
    const bottomTextTitleBoldCat = 'La Igualtat en Joc';
    const bottomTextTitleBoldEn = 'Equality on the Move';
    const bottomTextP1Cat = 'El Congrés La Igualtat en Joc és una plataforma de transferència de coneixement, interacció i aprenentatge per impulsar l’esport femení a Catalunya, organitzat per la Secretaria General de l’Esport i de l’Activitat Física a través de l’INEFC, i amb la coordinació dels departaments de la Presidència i d’Igualtat i Feminismes de la Generalitat de Catalunya.';
    const bottomTextP1En = 'The Congress Equality on the Move is a platform for the transfer of knowledge, interaction and learning in order to promote women\'s sport in Catalonia, organized by the General Secretariat of Sport and Physical Activity through the National Institute of Physical Education of Catalonia (INEFC), and with the coordination of the Presidency and Equality and Feminism departments of the Government of Catalonia (Generalitat de Catalunya).';
    const bottomTextP2Cat = 'Actualment, al nostre país, s’han desplegat clares polítiques de gènere en l’esport, i són molts els estudis i les experiències que s’han posat en pràctica sobre aquesta temàtica. A partir d’aquí, cal passar de les paraules als fets. Per això, aquest esdeveniment pretén reunir especialistes en gènere i esport de reconegut prestigi nacional i internacional amb professionals d’entitats esportives, administracions públiques i empreses del sector esportiu, líders i responsables de la presa de decisions en l’àmbit de l’activitat física i de l’esport; persones implicades en la igualtat entre homes i dones, esportistes, periodistes i professionals de la comunicació esportiva, i referents de l’àmbit acadèmic.';
    const bottomTextP2En = 'Currently, in our country, determined gender policies have been deployed in sport, and many studies and experiences have been put into practice on this topic. But the time has come to move on from words to action. This is why this event aims to bring together nationally and internationally prestigious specialists on gender and sports and professionals from sports organizations, public administrations and companies in the sports sector, leaders and decision-makers in the field of physical activity and sport; people involved in equality between men and women, athletes, journalists and sports communication professionals, and beacons in the academic field.';
    const bottomTextP3Cat = 'A partir de les dades i del coneixement de la situació actual, de la posada en comú d’experiències d’èxit i bones pràctiques a escala nacional i internacional, i de l’anàlisi de les dificultats i resistències que sorgeixen, en aquest Congrés es pretenen abordar preguntes com: què podem fer des de la nostra entitat per incrementar el nombre de dones esportistes, entrenadores, professionals o directives? Per on comencem un pla d’igualtat al nostre club? Quin paper poden tenir els homes en la promoció de la igualtat? Com fer de la nostra entitat un espai segur per als nois i les noies? Com podem fer efectives les polítiques públiques a favor de la igualtat en l’esport en el meu territori? Com podem impulsar un estil de vida més actiu i saludable per a les dones de totes les edats? Com podem donar la visibilitat i el reconeixement que es mereix a l’esport femení? Per què cal parlar encara d’igualtat en l’esport? Quines resistències sorgeixen al promoure la igualtat en l’esport? Quines idees cal trencar al s. XXI per afavorir la igualtat? I totes aquelles preguntes que es plantegin en el transcurs del Congrés.';
    const bottomTextP3En = 'Based on the data and knowledge of the current situation, the fact of sharing successful experiences and good practices at national and international levels, and the analysis of the difficulties and resistances that arise, in this Congress we seek to address questions such as: what can we do in our organization to increase the number of female athletes, coaches, professionals or managers? How do we start an equality plan in our club? Which role can men play in promoting equality? How can we turn our institution into a safe environment for young boys and girls? How can we make effective public policies in favor of equality in sport in my territory? How can we promote a more active and healthier lifestyle for women of all ages? How can we give women\'s sport the visibility and recognition it deserves? Why is it still necessary to talk about equality in sport? What hindrances do we face when promoting equality in sport? What ideas must we break through in the 21st century to favor equality? And all the other questions that arise during the Congress.';
    const bottomTextP4Cat = 'Per abordar aquestes qüestions, els continguts i espais de trobada s’estructuren en tres grans eixos: el sector públic, el sector associatiu i el sector comercial i de comunicació. Al voltant de cada un d’aquests eixos, s’ha elaborat un programa en el qual hi haurà ponències, diàlegs, tallers i espais d’intercanvi i interacció.';
    const bottomTextP4En = 'In order to address these issues, the contents and meeting spaces are organized around three main axes: the public sector, the associative sector and the commercial and communication sector. Around each of these axes, a program has been drawn up in which there will be presentations, dialogues, workshops and spaces for exchange and interaction.';
    const bottomTextP5Cat = 'Amb el Congrés La Igualtat en Joc volem posar el focus en el paper que podem representar cadascuna de nosaltres en l’impuls de l’esport femení a Catalunya.';
    const bottomTextP5En = 'With the Congress Equality on the Move we aim to focus on the role that each one of us can play in promoting women\'s sport in Catalonia.';
    const bottomTextBoldCat = 'Hi jugues?';
    const bottomTextBoldEn = 'Are you ready to move?';
    const titleTextCat = 'Codirectores';
    const titleTextEn = 'Co-directors'
    const drCat = 'Dra';
    const drEn = 'Dr';
    if(lang === Lang.en) {
      setTopHeaderText(topHeaderTextEn);
      setBottomHeaderText(bottomHeaderTextEn);
      setMainText(mainTextEn);
      setDownloadText(downloadTextEn);
      setEventText(addEventTextEn);
      setInfoText(infoTextEn);
      setLink(linkEn);
      setBottomTextTitle(bottomTextTitleEn);
      setBottomTextTitleBold(bottomTextTitleBoldEn);
      setBottomTextP1(bottomTextP1En);
      setBottomTextP2(bottomTextP2En);
      setBottomTextP3(bottomTextP3En);
      setBottomTextP4(bottomTextP4En);
      setBottomTextP5(bottomTextP5En);
      setBottomTextBold(bottomTextBoldEn);
      setTitleText(titleTextEn);
      setDr(drEn);
    }
    else {
      setTopHeaderText(topHeaderTextCat);
      setBottomHeaderText(bottomHeaderTextCat);
      setMainText(mainTextCat);
      setDownloadText(downloadTextCat);
      setEventText(addEventTextCat);
      setInfoText(infoTextCat);
      setLink(linkCat);
      setBottomTextTitle(bottomTextTitleCat);
      setBottomTextTitleBold(bottomTextTitleBoldCat);
      setBottomTextP1(bottomTextP1Cat);
      setBottomTextP2(bottomTextP2Cat);
      setBottomTextP3(bottomTextP3Cat);
      setBottomTextP4(bottomTextP4Cat);
      setBottomTextP5(bottomTextP5Cat);
      setBottomTextBold(bottomTextBoldCat);
      setTitleText(titleTextCat);
      setDr(drCat);
    }
    const e: CalendarEvent = {
      title: 'La Igualtat en Joc',
      location: 'INEFC BARCELONA',
      start: '2023-04-26 15:00:00 +0200',
      end: '2023-04-28 14:15:00 +0200'
    };
    console.log('userAgent', navigator.userAgent.toLowerCase());
    const useIcs = /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent.toLowerCase());
    // const useIcs = true;
    if(useIcs) {
      setEvent(ics(e));
    }
    else {
      setEvent(google(e));
    }
  }, []);

  return (
    <>
      <div style={{ height: 70 }}></div>
      <div className="mainLogo">
        <div className="text">
        {topHeaderText}<br/>{bottomHeaderText}
        </div>
      </div>
      <LanguageMenu lang={lang} /> 
      <Menu lang={lang} open={false} option={MenuOptions.inici} />
      <div className="dates">{topHeaderText}<br/>{bottomHeaderText}</div>
      <div className="video">
        <video src={'/content/Summary_LIEJ.mp4'} autoPlay={true} muted={true} controls={true} />
      </div>
      <div className="mainText">
        {mainText}
      </div>
      <a className="eventButton" href={'/content/Conclusions_LIEJ.pdf'} target="_blank">
        <div>CONCLUSIONS</div>
      </a>
      <div className="circle"></div>
      {lang === Lang.en && <a className="downloadButton" href={link} target="_blank">
        <div>{downloadText}</div>
      </a>}
      <h1 className="bottomTextTitle">{bottomTextTitle} <div className="subtitle">{bottomTextTitleBold}</div></h1>
      <div className="bottomText">
        {bottomTextP1}
        <br/><br/>
        {bottomTextP2}
        <br/><br/>
        {bottomTextP3}
        <br/><br/>
        {bottomTextP4}
        <br/><br/>
        {bottomTextP5}<span className="bold"> {bottomTextBold}</span>
      </div>
      <div className="boxes">
        <div className="box boxLeft">
          <div className="img homeLeft"></div>
          <div className="boxesLabel">{dr}. Susanna Soler Prat</div>
        </div>
        <div className="box boxRight">
          <div className="img homeRight"></div>
          <div className="boxesLabel">{dr}. Anna Vilanova Soler</div>
        </div>
      </div>
      <div className="boxesLabel">{titleText}</div>
    </>
  );
}

interface Props {
  lang: string
}

export default Home;