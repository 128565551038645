import React from 'react';
import { Link } from 'react-router-dom';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Galeria = ({ lang }: Props) => {
  let title = 'Galeria Fotogràfica';
  let main = 'Gràcies per la teva participació en el Congrés Internacional La Igualtat en Joc.';
  let secondary = 'Et donem accés a la galeria de fotos per tal que tinguis un record del Congrés i puguis descarregar-te’n les que t’interessin a';
  let fotografies = 'Fotografies';
  let expire = 'Aquest enllaç estarà actiu fins l’1 de setembre.';
  let keep = 'Continuem prement l’accelerador!';
  if(lang === Lang.en) {
    title = 'Photo Gallery';
    main = 'Thank you for your participation in the International Congress Equality on the Move.';
    secondary = 'We have set up a Photo Gallery so you can recall the memorable days we shared. You can download the pics of your interest at';
    fotografies = 'Photos';
    expire = 'This link will expire on September, 1.';
    keep = 'Keep accelerating!';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.galeria} />
      <div className="salaPremsa">
        <h1 className="optionTitle">{title}</h1>
        <div className="main">{main}</div>
        <div className="secondary">{secondary}</div>
        <div className="grid">
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgPhotos"></div>
              <a href={'https://media.inefc.es/index.php/s/BgBBQFTNrQBjqKx'} target={'_blank'}>
                <div className="gridBoxLabel">{fotografies}</div>
              </a>
              <div className="expiration">{expire}</div>
            </div>
          </div>
        </div>
        <div className="keep">{keep}</div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Galeria;