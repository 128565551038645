import React from 'react';
import { Link } from 'react-router-dom';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const LlibreResums = ({ lang }: Props) => {
  let title = 'Publicacions';
  let abstract = 'Llibre de resums';
  let conclusions = 'Conclusions'
  if(lang === Lang.en) {
    title = 'Publications'
    abstract = 'Book of abstracts';
    conclusions = 'Conclusions';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.llibreResums} />
      <div className="salaPremsa">
        <h1 className="optionTitle">{title}</h1>
        <div className="grid">
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgResums"></div>
              <a href="/programa/Book_of_Abstracts_LIEJ.pdf" download={'Book_of_Abstracts_LIEJ.pdf'}>
                <div className="gridBoxLabel">{abstract}</div>
              </a>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgConclusions"></div>
              <Link to={MenuOptions.conclusions.link}>
                <div className="gridBoxLabel">{conclusions}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default LlibreResums;