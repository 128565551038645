import React from 'react';
import { Link } from 'react-router-dom';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Error = ({ lang }: Props) => {
  let title = 'Ho sentim, s\'ha produït un error. Intenta’l de nou o consulta amb la teva entitat bancària.';
  let buttonLabel = 'TORNA AL FORMULARI';
  if(lang === Lang.en) {
    title = 'We\'re sorry, there\'s been an error. Try again or contact your bank.';
    buttonLabel = 'GO BACK TO THE FORM';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.error} />
      <div className="message">
        <div className="devider"></div>
        <div className="title">{title}</div>
        <Link to={'/formulari'} className="downloadButton"><div>{buttonLabel}</div></Link>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Error;