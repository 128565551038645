import React from 'react';
import { Event, Speaker } from '../model/Event';
import { Lang } from '../model/Lang';

const ProgramEventEn = ({ lang, titleItal, subtitleItal, event, onShowSpeakerDetails }: Props) => {
  let directedByLabel = 'Realitzat per';
  let speakerLabel = 'Ponent';
  let moderatorLabel = 'Modera';
  if(lang === Lang.en) {
    directedByLabel = 'Directed by';
    speakerLabel = 'Speaker';
    moderatorLabel = 'Moderator'
  }
  const speakerLine = (speaker: Speaker) => <span className="speakerName" onClick={() => onShowSpeakerDetails(speaker)}>{speaker.name}</span>;
  return (
    <div className="event">
      <div className={`title ${titleItal  && 'medItal'}`}>{event.title}</div>
      {event.subtitle && <div className={`title ${subtitleItal  && 'medItal'}`}>{event.subtitle}</div>}
      {event.subsubtitle && <div className="text">({event.subsubtitle})</div>}
      {event.speakers.map(s =>
        <div className="speaker">
          <div className="text">{speakerLine(s)}</div>
          <div className="text speakerPosition">{s.position}</div>
        </div>
      )}
      {event.moderator && <div className="modera">{moderatorLabel}: {event.moderator}</div>}
      {event.location && <div className="text">({event.location}){ event.translated ? <sup>*TS</sup> : ''}</div>}
    </div>
  );
}

interface Props {
  lang: string,
  event: Event,
  titleItal: boolean,
  subtitleItal: boolean,
  onShowSpeakerDetails: (speaker: Speaker) => void
}

export default ProgramEventEn;