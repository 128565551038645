import React, { CSSProperties, useState } from 'react';
import { Lang } from '../model/Lang';

const YesNo = ({ lang, onChange }: Props) => {
  const [value, setValue] = useState<boolean | null>(null);

  const getStyle = (selected: boolean): CSSProperties => {
    return {
      width: '28px',
      height: '28px',
      backgroundColor: selected ? '#000048' : '#FFFFFF',
      color: selected ? '#FFFFFF' : '#7f7fa3',
      border: 'none',
      fontSize: lang === Lang.cat ? '15px' : '14px',
      lineHeight: '28px',
      textAlign: 'center',
      padding: 0,
      marginRight: '8px'
    }; 
  }

  const onChangeValue = (value: boolean) => {
    setValue(value);
    onChange(value);
  }

  return (
    <div>
      <button onClick={() => onChangeValue(true)} style={getStyle(value === true)}>{lang === Lang.en ? 'YES' : 'SI'}</button>
      <button onClick={() => onChangeValue(false)} style={getStyle(value === false)}>NO</button>
    </div>
  );
}

interface Props {
  lang: string,
  onChange: (value: boolean) => void
}

export default YesNo;