import React from 'react';
import { Link } from 'react-router-dom';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const SalaPremsa = ({ lang }: Props) => {
  let title = 'Sala de premsa';
  let note = 'Nota de premsa';
  let releases = 'Recull de premsa';
  let spot = 'Espot del Congrés';
  let resources = 'Recursos per a xarxes socials';
  let logos = 'Logotips';
  let resums = 'Llibre de resums';
  let fotografies = 'Fotografies';
  if(lang === Lang.en) {
    title = 'Press Room';
    note = 'Press note';
    releases = 'Press releases';
    spot = 'Spot of the Congress';
    resources = 'Resources for social network';
    logos = 'Logos';
    resums = 'Llibre de resums';
    fotografies = 'Photos';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.salaPremsa} />
      <div className="salaPremsa">
        <h1 className="optionTitle">{title}</h1>
        <div className="grid">
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgNotes"></div>
              <Link to={MenuOptions.nota.link}>
                <div className="gridBoxLabel">{note}</div>
              </Link>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgRecull"></div>
              <Link to={MenuOptions.recull.link}>
                <div className="gridBoxLabel">{releases}</div>
              </Link>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgSpot"></div>
              <a href="/content/Spot_LIEJ.mp4" download={'Spot_LIEJ_horitzontal.mp4'}>
                <div className="gridBoxLabel">{spot}</div>
              </a>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgResources"></div>
              <Link to={MenuOptions.recursos.link}>
                <div className="gridBoxLabel">{resources}</div>
              </Link>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgPhotos"></div>
              <Link to={MenuOptions.fotografies.link}>
                <div className="gridBoxLabel">{fotografies}</div>
              </Link>
            </div>
          </div>
          <div className="gridCol">
            <div className="gridBox">
              <div className="gridBoxImg gridBoxImgLogos"></div>
              <Link to={MenuOptions.logotips.link}>
                <div className="gridBoxLabel">{logos}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default SalaPremsa;