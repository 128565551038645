import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Fotografies = ({ lang }: Props) => {
  let title = 'Fotografies';
  if(lang === Lang.en) {
    title = 'Photos';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.fotografies} />
      <div className="recull">
        <h1 className="optionTitle">{title}</h1>
        <a className="link" href="/content/26-04-23.zip" download={'26-04-23.zip'}>26/04/23</a>
        <a className="link" href="/content/27-04-23.zip" download={'27-04-23.zip'}>27/04/23</a>
        <a className="link" href="/content/28-04-23.zip" download={'28-04-23.zip'}>28/04/23</a>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Fotografies;