import React, { useState } from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';
import { IServiceFactory } from '../services/IServiceFactory';

const Export = ({ serviceFactory }: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  }

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
  }

  const onLogin = async () => {
    setLoading(true);
    try {
      const token = await serviceFactory.getAuthService().signIn(email, password);
      setToken(token);
    }
    catch(error) {
      console.log(error);
    }
    setLoading(false);
  }

  const onDownload = () => {
    setLoading(true);
    fetch('/inscripcions', {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'text/csv'
      }
    })
      .then(result => {
        if(result.ok) {
          return result.text();
        }
        throw new Error('You are not authorized');
      })
      .then(csv => {
        const blob = new Blob([ csv ], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', 'inscripcions.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(element);
        serviceFactory.getAuthService().signOut();
        setToken('');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <>
      <OptionHeader lang={Lang.cat} option={MenuOptions.export} />
      <div className="login">
        {!token ?
        <>
          <label>Email</label>
          <input type="text" onChange={onChangeEmail} />
          <label>Contraseña</label>
          <input type="password" onChange={onChangePassword} />
          <div className="buttonContainer">
            {!loading ? <button type="button" onClick={onLogin}>LOGIN</button> : <div className="loading"></div>}
          </div>
        </> :
        <div className="downloadContainer">
          <div className="buttonContainer">
            {!loading ? <button onClick={onDownload}>DESCARREGAR INSCRIPCIONS</button>: <div className="loading"></div>}
          </div>
        </div>}
      </div>
    </>
  );
}

interface Props {
  serviceFactory: IServiceFactory
}

export default Export;