import React from 'react';
import OptionHeader from '../components/OptionHeader';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';

const Duplicat = ({ lang }: Props) => {
  let title = 'Document d\'Identitat ja registrat.';
  let subtitle = 'Per a qualsevol modificació de les dades registrades escriu un correu a:';
  let thanks = 'Gràcies!';
  if(lang === Lang.en) {
    title = 'Identity card already registered.';
    subtitle = 'For any modification of the registered data, please write an email to:';
    thanks = 'Thank you';
  }
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.error} />
      <div className="message">
        <div className="devider"></div>
        <div className="title">{title}</div>
        <div className="subtitle wide">
          {subtitle}<br />
          <a href="mailto:congres.inefc@gencat.cat">congres.inefc@gencat.cat</a>
        </div>
        <div className="subtitle">{thanks}</div>
      </div>
    </>
  );
}

interface Props {
  lang: string
}

export default Duplicat;