import React, { useState } from 'react';
import parse from 'html-react-parser';
import OptionHeader from '../components/OptionHeader';
import ProgramEvent from '../components/ProgramEvent';
import { Speaker } from '../model/Event';
import { Lang } from '../model/Lang';
import { MenuOptions } from '../model/MenuOptions';
import { IServiceFactory } from '../services/IServiceFactory';
import ProgramEventEn from '../components/ProgramEventEn';
import { Activity } from '../components/Activity';
import { Opening } from '../components/Opening';
import { Final } from '../components/Final';

const Programacio = ({ lang, serviceFactory }: Props) => {
  const [speaker, setSpeaker] = useState<Speaker | null>(null);

  const onShowSpeakerDialog = (speaker: Speaker) => {
    setSpeaker(speaker);
  }

  const onCloseSpeakerDialog = () => {
    setSpeaker(null);
  }
  let downloadLabel = 'DESCARREGA’T EL PROGRAMA';
  let downloadSpecificLabel = 'DESCARREGA’T EL PROGRAMA ESPECÍFIC';
  let mainTitle = 'Congrés La Igualtat en Joc';
  let pauseLabel = 'PAUSA';
  let coffeeBreakLabel = 'PAUSA CAFÈ';
  let activityLabel = 'ACTIVITAT';
  let lunchLabel = 'DINAR';
  let wedTitle = 'DIMECRES 26/04';
  let time1 = '15.00 - 15.30 h';
  let time2 = '15.30 - 17.00 h';
  let time3 = '17.00 - 17.15 h';
  let time4 = '17.15 - 18.45 h';
  let time5 = '18.45 - 19.15 h';
  let time6 = '19.15 - 20.00 h';
  let thuTitle = 'DIJOUS 27/04';
  let time7 = '9.00 - 10.30 h';
  let time8 = '10.30 - 11.00 h';
  let time9 = '11.00 - 12.30 h';
  let time10 = '12.30 - 13.00 h';
  let time11 = '13.00 - 14.15 h';
  let time12 = '14.30 - 16.00 h';
  let time13 = '16.00 - 16.30 h';
  let time14 = '16.30 - 18.30 h';
  let time15 = '18.30 - 19.00 h';
  let time16 = '19.00 - 20.00 h';
  let friTitle = 'DIVENDRES 28/04';
  let time17 = '9.00 - 10.30 h';
  let time18 = '10.30 - 11.00 h';
  let time19 = '11.00 - 13.00 h';
  let time20 = '13.00 - 14.00 h';
  let time21 = '14.00 - 14.15 h';
  let footNote = 'Traducció simultània disponible';
  if(lang === Lang.en) {
    downloadLabel = 'DOWNLOAD THE PROGRAM';
    downloadSpecificLabel = 'DOWNLOAD THE SPECIFIC PROGRAM';
    mainTitle = 'Congress Equality on the Move';
    pauseLabel = 'PAUSE';
    coffeeBreakLabel = 'COFFEE BREAK';
    activityLabel = 'ACTIVITY';
    lunchLabel = 'LUNCH';
    wedTitle = 'WEDNESDAY, April 26';
    time1 = '3.00 - 3.30 p.m.';
    time2 = '3.30 - 5.00 p.m.';
    time3 = '5.00 - 5.15 p.m.';
    time4 = '5.15 - 6.45 p.m.';
    time5 = '6.45 - 7.15 p.m.';
    time6 = '7.15 - 8.00 p.m.';
    thuTitle = 'THURSDAY, April 27';
    time7 = '9.00 - 10.30 a.m.';
    time8 = '10.30 - 11.00 a.m.';
    time9 = '11.00 - 12.30 p.m.';
    time10 = '12.30 - 1.00 p.m.';
    time11 = '1.00 - 2.15 p.m.';
    time12 = '2.30 - 4.00 p.m.';
    time13 = '4.00 - 4.30 p.m.';
    time14 = '4.30 - 6.30 p.m.';
    time15 = '6.30 - 7.00 p.m.';
    time16 = '7.00 - 8.00 p.m.';
    friTitle = 'FRIDAY, April 28';
    time17 = '9.00 - 10.30 a.m.';
    time18 = '10.30 - 11.00 a.m.';
    time19 = '11.00 - 1.00 p.m.';
    time20 = '1.00 - 2.00 p.m.';
    time21 = '2.00 - 2.15 p.m.';
    footNote = 'Simultaneous translation provided';
  }
  const eventService = serviceFactory.getEventService();
  eventService.setLang(lang as Lang);
  const ponenciaI = eventService.get('ponenciaI');
  const dialegI = eventService.get('dialegI');
  const dialegII = eventService.get('dialegII');
  const dialegIII = eventService.get('dialegIII');
  const comunicacionsOralsI = eventService.get('comunicacionsOralsI');
  const ponenciaII = eventService.get('ponenciaII');
  const dialegIV = eventService.get('dialegIV');
  const dialegV = eventService.get('dialegV');
  const dialegVI = eventService.get('dialegVI');
  const taulaRodonda = eventService.get('taulaRodonda');
  const tallerI = eventService.get('tallerI');
  const tallerII = eventService.get('tallerII');
  const tallerIII = eventService.get('tallerIII');
  const tallerIV = eventService.get('tallerIV');
  const tallerV = eventService.get('tallerV');
  const tallerVI = eventService.get('tallerVI');
  const comunicacionsOralsII = eventService.get('comunicacionsOralsII');
  const womenInCoaching = eventService.get('womenInCoaching');
  const conclusions = eventService.get('conclusions');
  const cloenda = eventService.get('cloenda');
  return (
    <>
      <OptionHeader lang={lang} option={MenuOptions.programacio} />
      <a className="downloadButton" href={'/programa/PROGRAMA_CONGRES_LIEJ.pdf'} download={'PROGRAMA_CONGRES_LIEJ.pdf'} target="_blank">
        <div>{downloadLabel}</div>
      </a>
      <div className="programacio">
        <h1 className="optionTitle">{mainTitle}</h1>
        <div className='day dimecres'>{wedTitle}</div>
        <div className="time">{time1}</div>
        <Opening lang={lang} />
        <div className="time">{time2}</div>
        {ponenciaI && <ProgramEventEn lang={lang} titleItal={false} subtitleItal={lang === Lang.en} event={ponenciaI} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="time">{time3}</div>
        <div className="pause">{pauseLabel}</div>
        <div className="time">{time4}</div>
        {dialegI && <ProgramEvent lang={lang} event={dialegI} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {dialegII && <ProgramEvent lang={lang} event={dialegII} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {dialegIII && <ProgramEvent lang={lang} event={dialegIII} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="time">{time5}</div>
        <div className="pause">{coffeeBreakLabel}</div>
        <div className="time">{time6}</div>
        {comunicacionsOralsI && <ProgramEvent lang={lang} event={comunicacionsOralsI} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="event" style={{ paddingTop: 0 }}>
          <a className="downloadButton" href={'/programa/PROGRAMA_COMUNICACIONS_DIMECRES.pdf'} download={'PROGRAMA_COMUNICACIONS_DIMECRES.pdf'} target="_blank">
            <div>{downloadSpecificLabel}</div>
          </a>
        </div>
        <div className='day dijous'>{thuTitle}</div>
        <div className="time">{time7}</div>
        {ponenciaII && <ProgramEventEn lang={lang} titleItal={lang === Lang.en} subtitleItal={false} event={ponenciaII} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="time">{time8}</div>
        <div className="pause">{coffeeBreakLabel}</div>
        <div className="time">{time9}</div>
        {dialegIV && <ProgramEvent lang={lang} event={dialegIV} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {dialegV && <ProgramEvent lang={lang} event={dialegV} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {dialegVI && <ProgramEvent lang={lang} event={dialegVI} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="time">{time10}</div>
        <div className="pause"><Activity lang={lang} /></div>
        <div className="time">{time11}</div>
        <div className="pause">{lunchLabel}</div>
        <div className="time">{time12}</div>
        {taulaRodonda && <ProgramEvent lang={lang} event={taulaRodonda} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="time">{time13}</div>
        <div className="pause">{pauseLabel}</div>
        <div className="time">{time14}</div>
        {tallerI && <ProgramEvent lang={lang} event={tallerI} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerII && <ProgramEvent lang={lang} event={tallerII} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerIII && <ProgramEvent lang={lang} event={tallerIII} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerIV && <ProgramEvent lang={lang} event={tallerIV} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerV && <ProgramEvent lang={lang} event={tallerV} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerVI && <ProgramEvent lang={lang} event={tallerVI} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="time">{time15}</div>
        <div className="pause">{coffeeBreakLabel}</div>
        <div className="time">{time16}</div>
        {comunicacionsOralsII && <ProgramEvent lang={lang} event={comunicacionsOralsII} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="event" style={{ paddingTop: 0 }}>
          <a className="downloadButton" href={'/programa/PROGRAMA_COMUNICACIONS_DIJOUS.pdf'} download={'PROGRAMA_COMUNICACIONS_DIJOUS.pdf'} target="_blank">
            <div>{downloadSpecificLabel}</div>
          </a>
        </div>
        <div className='day divendres'>{friTitle}</div>
        <div className="time">{time17}</div>
        {womenInCoaching && <ProgramEventEn lang={lang} titleItal={lang === Lang.en} subtitleItal={false} event={womenInCoaching} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="time">{time18}</div>
        <div className="pause">{coffeeBreakLabel}</div>
        <div className="time">{time19}</div>
        {tallerI && <ProgramEvent lang={lang} event={{ ...tallerI, moderator: 'Xavier Iglesias' }} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerII && <ProgramEvent lang={lang} event={{ ...tallerII, moderator: 'Estela I. Farias' }} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerIII && <ProgramEvent lang={lang} event={{ ...tallerIII, moderator: 'Rosa Rodríguez' }} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerIV && <ProgramEvent lang={lang} event={{ ...tallerIV, moderator: 'Maribel Cuadrado' }} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerV && <ProgramEvent lang={lang} event={{ ...tallerV, moderator: 'Cati Lecumberri' }} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="devider"></div>
        {tallerVI && <ProgramEvent lang={lang} event={{ ...tallerVI, moderator: 'Alba Pardo' }} onShowSpeakerDetails={onShowSpeakerDialog} />}
        <div className="time">{time20}</div>
        {conclusions && <Final lang={lang} title={conclusions.title} speakerName="Susanna Soler i Anna Vilanova" speakerPosition={lang === Lang.cat ? 'Codirectores' : 'Co-directors'} location={conclusions.location || ''} />}
        <div className="time">{time21}</div>
        {cloenda && <Final lang={lang} title={cloenda.title} speakerName="Hble. Sra. Tània Verge i Mestre" speakerPosition="Consellera d’Igualtat i Feminismes" location={cloenda.location} />}
        <div className="event">
          <div className="footNote">*TS {footNote}</div>
        </div>
        {speaker && <>
          <div className="speakerDialogBackdrop" onClick={onCloseSpeakerDialog}></div>
          <div className="speakerDialog" style={{ top: window.scrollY }}>
            <div className="closeBtn" onClick={onCloseSpeakerDialog}></div>
            <div className="body">
              <img className="img" src={'/content/speakers/' + speaker.image}></img>
              <div className="title">{speaker.name}</div>
              <div><a href={speaker.companyLink} target={'_blank'}>{speaker.position}</a></div>
              <div className="bio">
                {speaker.bio.map((line: string) => <div>{parse(line)}</div>)}
              </div>
            </div>
          </div>
        </>}
      </div>
    </>
  );
}

interface Props {
  lang: string,
  serviceFactory: IServiceFactory
}

export default Programacio;