import React from 'react';
import { Lang } from '../model/Lang';

const Final = ({ lang, title, speakerName, speakerPosition, location }: Props) => {
  return (
    <div className="event opening">
      <div className={`title ${lang === Lang.en  && 'medItal'}`}>{title}</div>
      <div className="speaker">
        <div className="text">{speakerName}</div>
        <div className="text speakerPosition">{speakerPosition}</div>
      </div>
      <div className="text">({location})<sup>*TS</sup></div>
    </div>
  );
}

interface Props {
  lang: string,
  title: string,
  speakerName: string,
  speakerPosition: string,
  location: string
}

export { Final }