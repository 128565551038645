import { FirebaseApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, Auth, signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { IAuthService } from '../IAuthService';

export class AuthService implements IAuthService {
  private auth: Auth;

  constructor(app: FirebaseApp) {
    this.auth = getAuth(app);
  }

  async signIn(email: string, password: string): Promise<string> {
    await setPersistence(this.auth, browserSessionPersistence);
    const credentials = await signInWithEmailAndPassword(this.auth, email, password);
    const token = await credentials.user.getIdToken();
    return token;
  }

  async signOut(): Promise<void> {
    await signOut(this.auth);
  }
} 