import React, { useEffect } from 'react';
import { google, ics } from 'calendar-link';
import { CalendarEvent } from 'calendar-link';

const Event = () => {
  useEffect(() => {
    const e: CalendarEvent = {
      title: 'La Igualtat en Joc',
      location: 'INEFC BARCELONA',
      start: '2023-04-26 15:00:00 +0200',
      end: '2023-04-28 14:15:00 +0200'
    };
    console.log('userAgent', navigator.userAgent.toLowerCase());
    const useIcs = /(mac|iphone|ipod|ipad)/i.test(navigator.userAgent.toLowerCase());
    // const useIcs = true;
    let link = '';
    if(useIcs) {
      link = ics(e);
    }
    else {
      link = google(e);
    }
    const a = document.createElement('a');
      a.href = link;
      document.body.appendChild(a);
      a.dispatchEvent(
        new MouseEvent('click', { 
          bubbles: true, 
          cancelable: true, 
          view: window 
        })
      );
      document.body.removeChild(a);
  }, []);

  return (<div></div>);
}

export default Event;